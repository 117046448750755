import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        position: 'relative',

        [breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    header: {
        display: 'flex',
        width: '100%',
        padding: spacing(6, 6, 3),

        [breakpoints.down('sm')]: {
            padding: spacing(6, 2, 2),
        },
        [breakpoints.down('sm')]: {
            display: 'block',
            padding: spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',

        [breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: spacing(2),
            minHeight: '36px',
        },
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%',

        [breakpoints.down('sm')]: {
            marginBottom: spacing(1),
        },
    },
    actions: {
        display: 'flex',
    },
    options: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'stretch',
        minHeight: spacing(4),
        marginBottom: spacing(1),

        [breakpoints.down('sm')]: {
            marginTop: spacing(1)
        },
    },
    searchWrap: {
        width: '322px',
        flexShrink: 0,
        order: 1,

        [breakpoints.down('sm')]: {
            width: '100%',
            padding: 0
        }
    },
    divider: {
        [breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: spacing(3, 6, 0),
        backgroundColor: palette.common.white,

        [breakpoints.down('lg')]: {
            padding: spacing(2),
        },
        [breakpoints.down('sm')]: {
            padding: spacing(0, 0, 2),
        }
    },
    alertWrapper: {
        marginBottom: spacing(2),

        [breakpoints.down('sm')]: {
            marginBottom: spacing(0)
        }
    },
    resultsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: spacing(4),

        [breakpoints.down('sm')]: {
            marginBottom: spacing(2),
            padding: spacing(0, 2),
        }
    },
    resultsWrapper: {
        flex: 1,
    },
    columnChooser: {
        [breakpoints.down('md')]: {
            display: 'none'
        }
    },
    summaryWrap: {
        display: 'flex',
        position: 'relative',
        flexGrow: 'initial',
        alignSelf: 'normal',
        padding: '24px 16px 24px 16px',
        backgroundColor: '#F8F9FA',
        marginBottom: '1.5rem',
        border: '1px #E0E0E0 solid',
        borderRadius: '0.25rem',

        [breakpoints.down('sm')]: {
            borderRadius: spacing(0),
            marginBottom: '1rem',
        }
    },
    summaryText: {
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '0.625rem',
        color: '#666666',
        marginBottom: '0.25rem'
    },
    summaryHeading: {
        fontSize: '1.25rem',
        marginTop: '0px'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
}));

export default useStyles;
