import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStylesLocal = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
    searchWrap: {
        width: '370px',

        [breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    tab: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        minWidth: 'auto',
        minHeight: '40px',
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        borderRadius: '4px',
        textTransform: 'none',
        '& > span': {
            textTransform: 'none',
        },
        '&:hover': {
            color: palette.primary.main,
            backgroundColor: palette.primary.light,
            borderRadius: '4px 4px 0 0'
        },

        [breakpoints.down('sm')]: {
            flex: 'auto',
            minHeight: '30px',
            paddingLeft: spacing(1),
            paddingRight: spacing(1)
        }
    },
}));

export default useStylesLocal;
