import { sortBy } from 'utils/arrayMethods';
import {
    IFilterListItem,
    APPROVAL_TYPE,
    APPROVAL_TYPE_NAMES,
    APPROVAL_SUPERVISOR_TYPE,
    APPROVAL_SUPERVISOR_TYPE_NAMES,
    betshopPayoutPurchaseLimitsNames,
    betshopSupervisorThresholdNames,
    voucherExpirationSettingNames,
    BET_STATUS,
    BET_STATUS_NAMES,
    COMMAND_TYPES,
    COMMAND_SUBTYPES,
    PAYOUT_STATUS,
    PAYOUT_STATUS_NAMES,
    TICKETS_SEARCH_BY_DATES,
    VOUCHERS_STATUS,
    VOUCHERS_TYPE,
    ENTITY_TYPE,
} from 'const';
import { IUserAdd } from 'api/models/user';
import { IRoleAdd } from 'api/models/role';

import {
    IBetshopDetailInputData,
    IBetshopNumberInputData,
    IBetshopSupervisorThresholdsData,
} from 'api/models/network-management';
import {
    IReportCardTransactionsColumnsModel,
    IReportFinancialPerformanceHeadDataModel,
    IReportSupervisorColumnsModel,
    IReportVouchersModel
} from 'api/models/reports';

import { loadEposData } from 'context/epos';
import { loadKpiData } from 'context/kpi';

import Dashboard from 'pages/Dashboard/Dashboard';
import Epos from 'pages/Epos/Epos';
import UserList from 'pages/Users/UserList/UserList';
import RoleList from 'pages/Roles/RoleList/RoleList';
import AuditLogs from 'pages/Users/AuditLogs/AuditLogs';
import BetshopList from 'pages/Betshops/BetshopList/BetshopList';
import TicketsReport from 'pages/Reports/Tickets/TicketsReport';
import SupervisorReport from 'pages/Reports/Supervisor/SupervisorReport';
import CardTransactionsReport from 'pages/Reports/CardTransactions/CardTransactionsReport';
import VouchersReport from 'pages/Reports/Vouchers/VouchersReport';
import PlayerStatementReport from 'pages/Reports/PlayerStatement/PlayerStatementReport';
import FinancialPerformanceReport from 'pages/Reports/FinancialPerfomance/FinancialPerformanceReport';
import VoidsReprintsReport from 'pages/Reports/VoidsReprints/VoidsReprintsReport';
import OddsSheets from 'pages/OddsSheets/OddsSheets';

export const userView = 'users:view';
export const userAdd = 'users:add';
export const userEdit = 'users:edit';
export const userChangeBetshop = 'users:change-betshop';

const {
    RestartPC,
    RestartShell,
    RestartPrinter,
    ReInitBillAcceptor,
    EnableMaintenanceMode,
    DisableMaintenanceMode,
    ShellUpdate,
    ReloadShellSettings,
} = COMMAND_TYPES;

export interface IEposData {
	name: string;
	location: string;
	status: string;
	statusName: string;
	deliveryTime: string;
	errors: Array<string | number>;
	os?: string;
	shell?: string;
	ip?: string;
	sportsBookConnectionType?: string;
	sportsBookLeaderIP?: string;
	dataSetVersion?: string;
	minStake?: number;
}

interface ILogsData {
    name: string;
    date: string;
    user: string;
    action: string;
}

interface IUserData {
	name: string;
	passwordUpdate: string;
    status: string;
    userTypes: string;
    actionsMenu: string;
}

export interface IEposDataAll {
	location: string;
	statusName: string;
	deliveryTime: string;
	errors: Array<string | number>;
	os?: string;
	shell?: string;
	ip?: string;
	sportsBookConnectionType?: string;
	sportsBookLeaderIP?: string;
	dataSetVersion?: string;
	minStake?: number;
	updateVersion?: string;
	updateStatus?: string;
	monitoringAgentVersion?: string;
	linuxPatchVersion?: string;
	environmentType?: string;
}

export interface IRolesData {
	name: string;
	description: string;
}

export interface IHeadRowBase {
    id: string;
    label: string;
    permission?: boolean;
}
interface IHeadRowGeneric<T extends object> extends IHeadRowBase {
	id: Extract<keyof T, string>;
    position: number;
	label: string;
}

export interface IHeadRowTicketsReport {
    id: string;
    position: number;
	label: string;
    permission?: string;
	disablePadding?: boolean;
	width?: string;
}

export interface IHeadRowCardTransactionsReport extends IHeadRowGeneric<IReportCardTransactionsColumnsModel> {
	disablePadding?: boolean;
	width?: string;
}

export interface IHeadRowSupervisorReport extends IHeadRowGeneric<IReportSupervisorColumnsModel> {
	disablePadding?: boolean;
	width?: string;
}

export interface IHeadRowVouchersReport extends IHeadRowGeneric<IReportVouchersModel> {
	disablePadding?: boolean;
	width?: string;
}

export interface IHeadRowFinancialPerformanceReport extends IHeadRowGeneric<IReportFinancialPerformanceHeadDataModel> {
	disablePadding?: boolean;
	width?: string;
}

export interface IHeadRow {
	position: number;
	id: keyof IEposData;
	disablePadding: boolean;
	label: string;
	width?: string;
}

export interface IAuditLogsHeadRow {
	position: number;
    id: keyof ILogsData;
    label: string;
    width?: string;
}

export interface IOddsSheetsHeadRow {
	position: number;
    id: keyof ILogsData;
    label: string;
    align?: string;
}

export interface IUserHeadRow {
	position: number;
	id: keyof IUserData;
    label: string;
	width?: string;
	permissions?: Array<string>;
}

export interface IHeadRowAll {
	position: number;
	disablePadding: boolean;
	id: keyof IEposDataAll;
	label: string;
}

export interface IGenUserIputs {
	id: number;
	name: string;
	label: string;
	type?: string;
	required?: boolean;
}

export const menuItems = [
    {
        id: 1,
        'data-a': 'main-menu-dashboard',
        text: 'gen-dashboard',
        icon: 'dashboard',
        path: '/dashboard',
        loadData: loadKpiData,
        permission: 'dashboardPermission',
        component: Dashboard
    },
    {
        id: 2,
        'data-a': 'main-menu-health-monitor',
        text: 'gen-health-monitor',
        icon: 'tablet_android',
        path: '/epos',
        loadData: loadEposData,
        permission: 'eposPermission',
        component: Epos
    },
    {
        id: 3,
        'data-a': 'main-menu-users',
        text: 'gen-users',
        icon: 'people_alt',
        path: '/users',
        subpath: ['/users/'],
        permission: 'usersViewPermission',
        component: UserList
    },
    {
        id: 4,
        'data-a': 'main-menu-roles',
        text: 'gen-roles',
        icon: 'verified_user',
        path: '/role-list',
        subpath: ['/role-add', '/role-edit'],
        permission: 'rolesViewPermission',
        component: RoleList
    },
    {
        id: 5,
        'data-a': 'main-menu-audit-logs',
        text: 'gen-audit-logs',
        icon: 'history',
        path: '/audit-logs',
        permission: 'auditLogsPermission',
        component: AuditLogs
    },
    {
        id: 6,
        'data-a': 'main-menu-network',
        isSubMenu: true,
        text: 'gen-network',
        icon: 'group_work',
        subItems: [
            {
                id: '6.1',
                'data-a': 'main-menu-betshops',
                text: 'gen-betshops',
                permission: 'networkManagementBetshopsView',
                component: BetshopList,
                path: '/betshops',
                subpath: ['/betshops/'],
            },
        ]
    },
    {
        id: 8,
        'data-a': 'main-menu-reports',
        isSubMenu: true,
        text: 'gen-reports',
        icon: 'analytics',
        subItems: [
            {
                id: '8.1',
                'data-a': 'main-menu-tickets',
                text: 'gen-tickets',
                permission: 'reportTicketsView',
                component: TicketsReport,
                path: '/reports/tickets',
            },
            {
                id: '8.2',
                'data-a': 'main-menu-card-transactions',
                text: 'emp-reports-card-transactions-title',
                permission: 'reportCardTransactionsView',
                component: CardTransactionsReport,
                path: '/reports/card-transactions',
            },
            {
                id: '8.3',
                'data-a': 'main-menu-financial-performance',
                text: 'emp-reports-financial-performance-title',
                permission: 'reportFinancialPerformanceView',
                component: FinancialPerformanceReport,
                path: '/reports/financial-performance',
            },
            {
                id: '8.4',
                'data-a': 'main-menu-supervisor',
                text: 'gen-supervisor',
                permission: 'reportSupervisorView',
                component: SupervisorReport,
                path: '/reports/supervisor',
            },
            {
                id: '8.5',
                'data-a': 'main-menu-vouchers',
                text: 'gen-vouchers',
                permission: 'reportVouchersView',
                component: VouchersReport,
                path: '/reports/vouchers',
            },
            {
                id: '8.6',
                'data-a': 'main-menu-player-statement',
                text: 'gen-player-statement',
                permission: 'reportPlayerStatementView',
                component: PlayerStatementReport,
                path: '/reports/player-statement',
            },
            {
                id: '8.7',
                'data-a': 'main-menu-voids-reprints',
                text: 'gen-voids-reprints',
                permission: 'reportVoidsReprintsView',
                component: VoidsReprintsReport,
                path: '/reports/voids-reprints',
            },
        ]
    },
    {
        id: 9,
        'data-a': 'main-menu-odds-sheets',
        text: 'gen-odds-sheets',
        icon: 'library_books_alt',
        path: '/odds-sheets',
        permission: 'oddsSheetsPermission',
        component: OddsSheets
    },
];

export const generalInformationInputs: Array<IUserAdd> = [
    {
        id: 1,
        name: 'firstName',
        label: 'users-first-name',
        required: true,
        maxLength: 45
    },
    {
        id: 2,
        name: 'lastName',
        label: 'users-last-name',
        required: true,
        maxLength: 45
    },
    {
        id: 3,
        name: 'email',
        label: 'users-email',
        required: true,
        maxLength: 90,
        fullSize: true
    },
    {
        id: 4,
        name: 'userName',
        label: 'users-username',
        helperTextKey: 'users-username-cant-edit',
        required: true,
        maxLength: 90
    },
    {
        id: 5,
        name: 'position',
        label: 'users-position',
        helperTextKey: 'gen-optional',
        required: false,
        maxLength: 90
    },
];

export const generalEditUserInformationInputs: Array<IUserAdd> = [
    {
        id: 1,
        name: 'firstName',
        label: 'users-first-name',
        required: true,
        maxLength: 45
    },
    {
        id: 2,
        name: 'lastName',
        label: 'users-last-name',
        required: true,
        maxLength: 45
    },
    {
        id: 3,
        name: 'email',
        label: 'users-email',
        required: true,
        maxLength: 90,
        fullSize: true
    },
    {
        id: 4,
        name: 'userName',
        label: 'users-username',
        required: true,
        disabled: true,
        maxLength: 90
    },
    {
        id: 5,
        name: 'position',
        label: 'users-position',
        helperTextKey: 'gen-optional',
        required: false,
        maxLength: 90
    }
];

export const betshopDetailInputs: Array<IBetshopDetailInputData> = [
    {
        id: 1,
        name: 'betshopName',
        label: 'betshops-betshop-name',
        required: true,
        maxLength: 90
    },
    {
        id: 2,
        name: 'companyName',
        label: 'betshops-company-name',
        required: false,
        maxLength: 90
    },
    {
        id: 3,
        name: 'phoneNumber',
        label: 'betshops-betshop-phone',
        required: false,
        maxLength: 45
    },
    {
        id: 4,
        name: 'email',
        label: 'users-email',
        required: false,
        maxLength: 90
    },
    {
        id: 5,
        name: 'cardId',
        label: 'betshops-card-id',
        required: false,
        maxLength: 90
    },
    {
        id: 6,
        name: 'taxNumber',
        label: 'betshops-tax-number',
        required: false,
        maxLength: 90
    },
    {
        id: 7,
        name: 'rfc',
        label: 'betshops-rfc',
        required: false,
        maxLength: 45
    },
    {
        id: 8,
        name: 'licenseType',
        label: 'gen-type',
        required: true,
        col: { sm: 1, xs: 1 }
    },
    {
        id: 9,
        name: 'licenseId',
        label: 'betshops-license-id',
        required: true,
        maxLength: 45,
        col: { sm: 'auto', xs: 'auto' }
    },
    {
        id: 10,
        name: 'address',
        label: 'gen-address',
        required: true,
        maxLength: 255,
        col: { sm: 12, xs: 12 }
    },
    {
        id: 11,
        name: 'city',
        label: 'betshops-city',
        required: true,
        maxLength: 45,
        col: { sm: 12, xs: 12 }
    },
];

export const bethopPayoutPurchaseLimitsInputs: Array<IBetshopNumberInputData> = [
    {
        name: betshopPayoutPurchaseLimitsNames.limitWinningsAmount,
        label: 'betshops-self-payout',
        isCurrency: true,
        maxLength: 15,
        min: 0,
        required: true,
    },
    {
        name: betshopPayoutPurchaseLimitsNames.limitPurchaseAmount,
        label: 'betshops-self-purchase',
        isCurrency: true,
        maxLength: 15,
        min: 0,
        required: true,
    }
];

export const bethopSupervisorThresholds: Array<IBetshopSupervisorThresholdsData> = [
    {
        id: APPROVAL_TYPE[APPROVAL_TYPE.W2G] as keyof typeof APPROVAL_TYPE,
        tieWord: 'gen-and',
        inputs: [
            {
                name: betshopSupervisorThresholdNames.w2GPayoutLimit,
                label: 'gen-payout',
                isCurrency: true,
                maxLength: 15,
                min: 0,
                required: true,
            },
            {
                name: betshopSupervisorThresholdNames.w2GWagerMultiple,
                label: 'gen-odds',
                isInteger: true,
                maxLength: 15,
                min: 0,
                required: true,
            },
        ],
    }, {
        id: APPROVAL_TYPE[APPROVAL_TYPE.MTL] as keyof typeof APPROVAL_TYPE,
        inputs: [
            {
                name: betshopSupervisorThresholdNames.mtlCashin,
                label: 'gen-cashin',
                isCurrency: true,
                maxLength: 15,
                min: 0,
                required: true,
            },
            {
                name: betshopSupervisorThresholdNames.mtlCashout,
                label: 'gen-cashout',
                isCurrency: true,
                maxLength: 15,
                min: 0,
                required: true,
            },
        ]
    }, {
        id: APPROVAL_TYPE[APPROVAL_TYPE.CTR] as keyof typeof APPROVAL_TYPE,
        inputs: [
            {
                name: betshopSupervisorThresholdNames.ctrCashin,
                label: 'gen-cashin',
                isCurrency: true,
                maxLength: 15,
                min: 0,
                required: true,
            },
            {
                name: betshopSupervisorThresholdNames.ctrCashout,
                label: 'gen-cashout',
                isCurrency: true,
                maxLength: 15,
                min: 0,
                required: true,
            },
        ]
    }
];

export const supervisorSettingsInputs = bethopSupervisorThresholds
    .map(({ inputs }) => inputs)
    .flat();

export const voucherExpirationInputs: Array<IBetshopNumberInputData> = [
    {
        name: voucherExpirationSettingNames.expPeriod,
        isInteger: true,
        maxLength: 3,
        min: 1,
        required: true,
    },
    {
        name: voucherExpirationSettingNames.timeUnit,
        isInteger: true,
        required: true,
    }
];

export const roleOverviewInputs: Array<IRoleAdd> = [
    {
        id: 1,
        name: 'Name',
        label: 'hm-column-name',
        helperTextKey: 'role-rolename-cant-edit',
        required: true,
        disabled: true,
        maxLength: 100
    },
    {
        id: 2,
        name: 'Description',
        label: 'gen-description',
        helperTextKey: 'gen-optional',
        required: false,
        maxLength: 255
    }
];

export const headRowsDefault: Array<IHeadRow> = [
    { position: 1, id: 'name', disablePadding: true, label: 'hm-column-name' },
    { position: 2, id: 'location', disablePadding: false, label: 'gen-betshop' },
    { position: 3, id: 'statusName', disablePadding: false, label: 'hm-column-status', width: '120px' },
    { position: 4, id: 'deliveryTime', disablePadding: false, label: 'hm-column-up-down-time' },
    { position: 5, id: 'errors', disablePadding: false, label: 'hm-column-warnings' }
];

export const headRowsAll: Array<IHeadRowAll> = [
    { position: 2, id: 'location', disablePadding: false, label: 'gen-betshop' },
    { position: 3, id: 'statusName', disablePadding: false, label: 'hm-column-status' },
    { position: 4, id: 'deliveryTime', disablePadding: false, label: 'hm-column-up-down-time' },
    { position: 5, id: 'errors', disablePadding: false, label: 'hm-column-warnings' },
    { position: 6, id: 'os', disablePadding: false, label: 'gen-os' },
    { position: 7, id: 'linuxPatchVersion', disablePadding: false, label: 'gen-linux-patch-version' },
    { position: 8, id: 'shell', disablePadding: false, label: 'gen-shell' },
    { position: 9, id: 'monitoringAgentVersion', disablePadding: false, label: 'hm-column-monitoring-agent-version' },
    { position: 10, id: 'ip', disablePadding: false, label: 'hm-column-ip' },
    { position: 11, id: 'dataSetVersion', disablePadding: false, label: 'hm-column-bill-validator' },
    { position: 12, id: 'minStake', disablePadding: false, label: 'hm-column-minimum-stake' },
    { position: 13, id: 'sportsBookConnectionType', disablePadding: false, label: 'hm-column-connection-type' },
    { position: 14, id: 'sportsBookLeaderIP', disablePadding: false, label: 'hm-column-leader-ip' },
    { position: 15, id: 'updateVersion', disablePadding: false, label: 'hm-column-update-version' },
    { position: 16, id: 'updateStatus', disablePadding: false, label: 'hm-column-update-status' },
    { position: 17, id: 'environmentType', disablePadding: false, label: 'gen-environment' },
];

export const headTicketsReportColumnsDefault = sortBy<IHeadRowTicketsReport>('position', [
    { position: 1, id: 'ticketId', label: 'emp-reports-ticket-number' },
    { position: 2, id: 'betStatus', label: 'emp-reports-ticket-status' },
    { position: 4, id: 'issuedBetShopName', label: 'emp-reports-ticket-betshop' },
    { position: 5, id: 'issuedEntityName', label: 'emp-reports-ticket-point-of-sale' },
    { position: 7, id: 'creationDate', label: 'emp-reports-ticket-sale-date' },
    { position: 8, id: 'settledDate', label: 'emp-reports-ticket-settlement-date' },
    { position: 10, id: 'costAmount', label: 'emp-reports-ticket-cost' },
    { position: 19, id: 'toPayAmount', label: 'emp-reports-ticket-to-pay' },
]);

export const headTicketsReportSelectableColumns = sortBy<IHeadRowTicketsReport>('position', [
    ...headTicketsReportColumnsDefault,
    { position: 3, id: 'betType', label: 'emp-reports-ticket-bet-type' },
    { position: 6, id: 'loyaltyUserKey', label: 'emp-reports-ticket-loyalty-id' },
    { position: 9, id: 'paymentExternalTransactionId', label: 'emp-reports-transaction-rrn-id' },
    { position: 11, id: 'stakeAmount', label: 'emp-reports-ticket-stake' },
    { position: 12, id: 'stakeTaxPercent', label: 'emp-reports-ticket-stake-tax-rate', permission: 'DisplayStakeTaxColumns' },
    { position: 13, id: 'stakeTaxAmount', label: 'emp-reports-ticket-stake-tax-amount', permission: 'DisplayStakeTaxColumns' },
    { position: 14, id: 'toWinAmount', label: 'emp-reports-ticket-to-win' },
    { position: 15, id: 'winningsAmount', label: 'emp-reports-ticket-winnings-amount' },
    { position: 16, id: 'actualWinAmount', label: 'emp-reports-actual-win', permission: 'DisplayActualWinColumn' },
    { position: 17, id: 'winTaxPercent', label: 'emp-reports-ticket-win-tax-rate', permission: 'DisplayWinTaxColumns' },
    { position: 18, id: 'winTaxAmount', label: 'emp-reports-ticket-win-tax-amount', permission: 'DisplayWinTaxColumns' },
    { position: 20, id: 'comboBonusAmount', label: 'emp-reports-ticket-combo-bonus' },
    { position: 21, id: 'totalPaidAmount', label: 'emp-reports-ticket-payout-amount' },
    { position: 22, id: 'expirationDate', label: 'emp-reports-ticket-expiration-date' },
    { position: 23, id: 'paidDate', label: 'emp-reports-ticket-payout-date' },
]);

export const headVouchersReportColumnsDefault = sortBy<IHeadRowVouchersReport>('position', [
    { position: 1, id: 'voucherId', label: 'emp-reports-vouchers-voucher' },
    { position: 2, id: 'issueDate', label: 'emp-reports-vouchers-issue-date' },
    { position: 3, id: 'issueSessionId', label: 'emp-reports-vouchers-issue-session' },
    { position: 4, id: 'issuedByEntityName', label: 'emp-reports-vouchers-issued-by' },
    { position: 5, id: 'issuedByBetshopName', label: 'emp-reports-vouchers-issue-sportsbook' },
    { position: 6, id: 'cashoutDate', label: 'emp-reports-vouchers-cash-out-date' },
    { position: 7, id: 'cashoutSessionId', label: 'emp-reports-vouchers-cash-out-session' },
    { position: 8, id: 'cashoutByEntityName', label: 'emp-reports-vouchers-cashed-out-by' },
    { position: 10, id: 'cost', label: 'emp-reports-vouchers-voucher-cost' },
]);

export const headVouchersReportSelectableColumns = sortBy<IHeadRowVouchersReport>('position', [
    ...headVouchersReportColumnsDefault,
    { position: 9, id: 'cashoutByBetshopName', label: 'emp-reports-vouchers-cash-out-sportsbook' },
    { position: 11, id: 'expirationDate', label: 'emp-reports-vouchers-expiration-date' },
]);

export const ticketDetailsMainHead = [
    {
        key: 'maxOdds',
        label: 'emp-reports-ticket-cost',
    },
    {
        key: 'costAmount',
        label: 'emp-reports-ticket-total-odds',
    },
    {
        key: 'toWinAmount',
        label: 'emp-reports-ticket-to-win',
    },
    {
        key: 'toPayAmount',
        label: 'emp-reports-ticket-to-pay',
    },
];

export const ticketDetailsMainDetails = [
    { key: 'emp-reports-ticket-stake', label: 'stakeAmount', currency: true },
    { key: 'emp-reports-ticket-stake-tax-amount', label: 'stakeTaxAmount', currency: true, permission: 'DisplayStakeTaxColumns' },
    { key: 'emp-reports-ticket-sale-date', label: 'creationDate', date: true },
    { key: 'emp-reports-ticket-point-of-sale', label: 'issuedEntityName' },
    { key: 'emp-reports-ticket-workstation', label: 'workstationName' },
    { key: 'emp-reports-ticket-loyalty-id', label: 'loyaltyUserKey' },
    { key: 'emp-reports-ticket-betshop', label: 'issuedBetShopName' },
    { key: 'emp-reports-ticket-expiration-date', label: 'expirationDate', date: true },
    { key: 'emp-reports-ticket-settlement-date', label: 'settledDate', date: true },
    { key: 'emp-reports-ticket-combo-bonus', label: 'comboBonusAmount', currency: true },
    { key: 'emp-reports-ticket-win-tax-amount', label: 'winTaxAmount', currency: true, permission: 'DisplayWinTaxColumns' },
    { key: 'emp-reports-ticket-payout-amount', label: 'totalPaidAmount', currency: true },
    { key: 'emp-reports-ticket-paid-out-by', label: 'paidByEntityName' },
    { key: 'emp-reports-ticket-payout-workstation', label: 'paidByWorkstationName' },
    { key: 'emp-reports-ticket-payout-betshop', label: 'paidByBetShopName' },
    { key: 'emp-reports-ticket-payout-date', label: 'paidDate', date: true },
    { key: 'emp-reports-transaction-rrn-id', label: 'paymentExternalTransactionId' },
];

export const voucherDetailsMainDetails = [
    { key: 'emp-reports-vouchers-details-issue-sportsbook', label: 'issuedByBetshopName' },
    { key: 'emp-reports-vouchers-details-issued-by', label: 'issuedByEntityName' },
    { key: 'emp-reports-vouchers-details-issue-workstation', label: 'workstationNameIssued' },
    { key: 'emp-reports-vouchers-details-issue-date', label: 'issueDate', date: true },
    { key: 'emp-reports-vouchers-details-cashout-sportsbook', label: 'cashoutByBetshopName' },
    { key: 'emp-reports-vouchers-details-cashout-by', label: 'cashoutByEntityName' },
    { key: 'emp-reports-vouchers-details-cashout-workstation', label: 'workstationNameRedeemed' },
    { key: 'emp-reports-vouchers-details-cashout-date', label: 'cashoutDate', date: true },
];

export const detailsAuthorizeReprintKeysForCheck = [
    'lastAuthorizedDate',
    'lastAuthorizedEntityName',
    'authorizeReprintReason',
];

export const ticketDetailsTicketVoidKeysForCheck = [
    'canceledByEntityName',
    'reason',
    'comment',
];

export const ticketDetailsAuthorizeReprintReason = [
    { id: 1, label: 'emp-reports-ticket-authorize-reprint-modal-lost-ticket' },
    { id: 2, label: 'emp-reports-ticket-authorize-reprint-modal-damaged-ticket' },
    { id: 3, label: 'emp-reports-ticket-authorize-reprint-modal-preventative-actions' },
    { id: 4, label: 'emp-reports-ticket-authorize-reprint-modal-investigation' },
    { id: 5, label: 'emp-reports-ticket-authorize-reprint-modal-printout-not-printed' },
];

export const voucherDetailsAuthorizeReprintReason = [
    { id: 1, label: 'emp-reports-voucher-authorize-reprint-modal-lost-voucher' },
    { id: 2, label: 'emp-reports-voucher-authorize-reprint-modal-damaged-voucher' },
    { id: 3, label: 'emp-reports-voucher-authorize-reprint-modal-preventative-actions' },
    { id: 4, label: 'emp-reports-voucher-authorize-reprint-modal-investigation' },
    { id: 5, label: 'emp-reports-voucher-authorize-reprint-modal-printout-not-printed' },
];

export const headCardTransactionsReportColumns = sortBy<IHeadRowCardTransactionsReport>('position', [
    { position: 1, id: 'creationDate', label: 'emp-reports-supervisor-approval-date' },
    { position: 2, id: 'transactionRRNId', label: 'emp-reports-transaction-rrn-id' },
    { position: 3, id: 'betshopName', label: 'emp-reports-supervisor-betshop' },
    { position: 4, id: 'issuerName', label: 'emp-reports-transaction-issuer' },
    { position: 5, id: 'receiptNumber', label: 'emp-reports-transaction-receipt-id' },
    { position: 6, id: 'amount', label: 'emp-reports-transaction-amount' },
    { position: 7, id: 'itemIds', label: 'gen-tickets' },
    { position: 8, id: 'status', label: 'emp-reports-ticket-status' },
    { position: 9, id: 'transactionId', label: 'emp-reports-transaction-id' },
]);

export const headSupervisorReportColumns = sortBy<IHeadRowSupervisorReport>('position', [
    { position: 1, id: 'approvalDate', label: 'emp-reports-supervisor-approval-date' },
    { position: 2, id: 'betshopName', label: 'emp-reports-supervisor-betshop' },
    { position: 3, id: 'supervisorName', label: 'emp-reports-supervisor-name' },
    { position: 4, id: 'approvalTypes', label: 'emp-reports-supervisor-approval' },
    { position: 5, id: 'transactionType', label: 'emp-reports-supervisor-transaction' },
    { position: 6, id: 'amount', label: 'emp-reports-supervisor-amount' },
    { position: 7, id: 'documentId', label: 'emp-reports-supervisor-document-id' },
]);

export const headFinancialPerformanceReportColumns = sortBy<IHeadRowFinancialPerformanceReport>('position', [
    { position: 1, id: 'entityName', label: 'emp-reports-financial-performance-entity-title', width: '20%' },
    { position: 2, id: 'turnover', label: 'emp-reports-financial-performance-handle-title' },
    { position: 3, id: 'winnings', label: 'emp-reports-financial-performance-winnings-title' },
    { position: 4, id: 'grossWin', label: 'emp-reports-financial-performance-gross-win-title' },
    { position: 5, id: 'winningsPaid', label: 'emp-reports-financial-performance-winnings-paid-title' },
    { position: 6, id: 'numberOfTotalBets', label: 'emp-reports-financial-performance-total-bets-title' },
    { position: 7, id: 'handlePerBet', label: 'emp-reports-financial-performance-handle-per-bet-title' }
]);

export const headVoidsReprintsReportColumns = [
    { id: 'documentId', label: 'emp-reports-voids-reprints-documentId-title' },
    { id: 'entityName', label: 'emp-reports-voids-reprints-executed-by-title' },
    { id: 'executeDate', label: 'emp-reports-voids-reprints-executed-on-title' },
    { id: 'supervisorName', label: 'emp-reports-voids-reprints-authorized-by-title' },
    { id: 'approvalDate', label: 'emp-reports-voids-reprints-authorized-on-title' },
    { id: 'betshopName', label: 'emp-reports-voids-reprints-sportsbook-title' },
    { id: 'amount', label: 'emp-reports-voids-reprints-cost-title' }
];

export const headUsers: Array<IUserHeadRow> = [
    { position: 1, id: 'name', label: 'hm-column-name', width: 'auto' },
    { position: 4, id: 'passwordUpdate', label: 'users-password-update', width: '15%' },
    { position: 5, id: 'status', label: 'hm-column-status', width: '15%' },
    { position: 6, id: 'userTypes', label: 'users-user-types', width: '15%' },
    { position: 7, id: 'actionsMenu', label: '', width: '80px', permissions: ['usersBlockUnblockPermission', 'usersResetPasswordPermission'] }
];

export const headRoles: Array<any> = [
    { position: 1, id: 'name', disablePadding: true, label: 'hm-column-name' },
    { position: 2, id: 'description', disablePadding: true, label: 'gen-description' },
];

export const headAuditLogs: Array<IAuditLogsHeadRow> = [
    { position: 1, id: 'date', label: 'audit-logs-column-date', width: '200px' },
    { position: 3, id: 'user', label: 'users-username' },
    { position: 4, id: 'action', label: 'audit-logs-column-action' }
];

export const headOddsSheets: Array<IOddsSheetsHeadRow> = [
    { position: 1, id: 'name', label: 'emp-odds-sheets-column-name-title', align: 'left' },
    { position: 2, id: 'action', label: 'emp-odds-sheets-column-action-title', align: 'right' }
];

export const betshopListHeader = [
    { position: 1, id: 'name', label: 'hm-column-name', width: '30%' },
    { position: 2, id: 'balance', label: 'hmd-terminal-info-balance', width: '15%' },
    { position: 3, id: 'address', label: 'gen-address', width: '25%' },
];

interface ITicketsSearchByDateOption {
    id: keyof typeof TICKETS_SEARCH_BY_DATES;
    label: string;
}

export const ticketsSearchByDateOptions: Array<ITicketsSearchByDateOption> = [
    { id: 'creationDate', label: 'emp-reports-ticket-sale-date' },
    { id: 'settledDate', label: 'emp-reports-ticket-settlement-date' },
    { id: 'voidedDate', label: 'emp-reports-ticket-voided-date' },
    { id: 'cancelationDate', label: 'emp-reports-ticket-cancelation-date' },
    { id: 'paidDate', label: 'emp-reports-ticket-payout-date' },
    { id: 'expirationDate', label: 'emp-reports-ticket-expiration-date' }
];

interface IVouchersName {
    name: string;
}

interface IVouchersStatusData extends IVouchersName {
    id: keyof typeof VOUCHERS_STATUS;
}

interface IVouchersTypeData extends IVouchersName {
    id: keyof typeof VOUCHERS_TYPE;
}

interface IEntityTypeData extends IVouchersName {
    id: keyof typeof ENTITY_TYPE;
}

export const vouchersStatusData: Array<IVouchersStatusData> = [
    { id: 'issued', name: 'voucher-status-issued' },
    { id: 'cashed', name: 'voucher-status-cashed' },
    { id: 'unpaid', name: 'voucher-status-unpaid' },
];

export const vouchersTypeData: Array<IVouchersTypeData> = [
    { id: 'regular', name: 'voucher-type-regular' },
    { id: 'collectedMoney', name: 'voucher-type-collected-money' },
];

export const entityTypeData: Array<IEntityTypeData> = [
    { id: 'cashier', name: 'entity-type-cashier' },
    { id: 'terminal', name: 'entity-type-kiosk' },
];

interface IBetStatusFilterItem extends IFilterListItem {
    id: BET_STATUS;
}

export const betStatusFilterList: Array<IBetStatusFilterItem> = [
    { id: BET_STATUS.OPENED, name: BET_STATUS_NAMES.OPENED },
    { id: BET_STATUS.LOST, name: BET_STATUS_NAMES.LOST },
    { id: BET_STATUS.WON, name: BET_STATUS_NAMES.WON },
    { id: BET_STATUS.DRAW, name: BET_STATUS_NAMES.DRAW },
    { id: BET_STATUS.CANCELED, name: BET_STATUS_NAMES.CANCELED },
    { id: BET_STATUS.VOIDED, name: BET_STATUS_NAMES.VOIDED },
    { id: BET_STATUS.CASHOUT, name: BET_STATUS_NAMES.CASHOUT },
];

interface IPayoutStatusFilterItem extends IFilterListItem {
    id: PAYOUT_STATUS;
}

export const payoutStatusFilterList: Array<IPayoutStatusFilterItem> = [
    { id: PAYOUT_STATUS.PAID, name: PAYOUT_STATUS_NAMES.PAID },
    { id: PAYOUT_STATUS.UNPAID, name: PAYOUT_STATUS_NAMES.UNPAID },
    { id: PAYOUT_STATUS.SOLD, name: PAYOUT_STATUS_NAMES.SOLD },
];

interface ISupervisorApprovalFilterItem extends IFilterListItem {
    id: APPROVAL_TYPE;
}

export const supervisorApprovalFilterList: Array<ISupervisorApprovalFilterItem> = [
    { id: APPROVAL_TYPE.W2G, name: APPROVAL_TYPE_NAMES.W2G },
    { id: APPROVAL_TYPE.MTL, name: APPROVAL_TYPE_NAMES.MTL },
    { id: APPROVAL_TYPE.CTR, name: APPROVAL_TYPE_NAMES.CTR },
];

interface ISupervisorApprovalVoidsReprintsFilterItem extends IFilterListItem {
    id: APPROVAL_SUPERVISOR_TYPE;
}

export const supervisorApprovalVoidsReprintsFilterList: Array<ISupervisorApprovalVoidsReprintsFilterItem> = [
    { id: APPROVAL_SUPERVISOR_TYPE.TicketVoid, name: APPROVAL_SUPERVISOR_TYPE_NAMES.TicketVoid },
    { id: APPROVAL_SUPERVISOR_TYPE.TicketReprint, name: APPROVAL_SUPERVISOR_TYPE_NAMES.TicketReprint },
    { id: APPROVAL_SUPERVISOR_TYPE.VoucherReprint, name: APPROVAL_SUPERVISOR_TYPE_NAMES.VoucherReprint },
];

export const bulkActions = [
    {
        commandTypeId: RestartPC,
        title: 'hm-bulk-action-restart-terminal',
        description: 'hm-bulk-action-restart-terminal-desc',
        descriptionPlural: 'hm-bulk-action-restart-terminal-desc-plural',
        permission: 'restartTerminalPermission'
    },
    {
        commandTypeId: RestartShell,
        title: 'hm-bulk-action-restart-shell',
        description: 'hm-bulk-action-restart-shell-desc',
        descriptionPlural: 'hm-bulk-action-restart-shell-desc-plural',
        permission: 'restartShellPermission'
    },
    {
        commandTypeId: RestartPrinter,
        title: 'hm-bulk-action-restart-printer',
        description: 'hm-bulk-action-restart-printer-desc',
        descriptionPlural: 'hm-bulk-action-restart-printer-desc-plural',
        permission: 'restartPrinterPermission'
    },
    {
        commandTypeId: ReInitBillAcceptor,
        title: 'hm-bulk-action-reinit-bill-validator',
        description: 'hm-bulk-action-reinit-bill-validator-desc',
        descriptionPlural: 'hm-bulk-action-reinit-bill-validator-desc-plural',
        permission: 'reinitBillValPermission'
    },
    {
        commandTypeId: ReloadShellSettings,
        title: 'hm-bulk-action-reload-shell-settings',
        description: 'hm-bulk-action-reload-shell-settings-desc',
        descriptionPlural: 'hm-bulk-action-reload-shell-settings-desc-plural',
        permission: 'reloadShellSettingsPermission'
    }
];

export const MaintenanceModeDisable = {
    commandTypeId: DisableMaintenanceMode,
    title: 'hm-bulk-action-disable-maintenance-mode',
    description: 'hm-bulk-action-disable-maintenance-mode-desc',
    descriptionPlural: 'hm-bulk-action-disable-maintenance-mode-desc-plural',
    permission: 'maintenancePermission'
};

export const MaintenanceModeEnable = {
    commandTypeId: EnableMaintenanceMode,
    title: 'hm-bulk-action-enable-maintenance-mode',
    description: 'hm-bulk-action-enable-maintenance-mode-desc',
    imagesDescription: 'hm-bulk-action-enable-maintenance-mode-img-desc',
    textDisplayDescription: 'hm-bulk-action-enable-maintenance-mode-display-desc',
    textDisplayPlaceholder: 'hm-bulk-action-enable-maintenance-mode-display-placeholder',
    textError: 'hm-bulk-action-enable-maintenance-mode-text-error',
    uploading: 'hm-bulk-action-enable-maintenance-mode-uploading',
    deleteImage: 'hm-bulk-action-enable-maintenance-mode-delete',
    uploadImage: 'hm-bulk-action-enable-maintenance-mode-upload-img',
    notificationToPlayer: 'hm-bulk-action-enable-maintenance-notification-to-player',
    timerDescription: 'hm-bulk-action-enable-maintenance-timer-description',
    minutes: 'hm-bulk-action-enable-maintenance-timer-minutes',
    minutesError: 'hm-bulk-action-enable-maintenance-timer-minutes-error',
    permission: 'maintenancePermission'
};

export const UpdateShell = {
    commandTypeId: ShellUpdate,
    title: 'hm-bulk-action-shell-update-title',
    permission: 'updateShellPermission'
};

export const SwitchToProductionBulkAction = {
    commandTypeId: COMMAND_SUBTYPES.SwitchEnviromentProduction,
    title: 'hm-bulk-action-switch-env-production',
    description: 'hm-bulk-action-switch-env-production-desc',
    descriptionPlural: 'hm-bulk-action-switch-env-production-desc-plural',
    permission: 'switchEnvironment',
};

export const SwitchToCanaryBulkAction = {
    commandTypeId: COMMAND_SUBTYPES.SwitchEnviromentCanary,
    title: 'hm-bulk-action-switch-env-canary',
    description: 'hm-bulk-action-switch-env-canary-desc',
    descriptionPlural: 'hm-bulk-action-switch-env-canary-desc-plural',
    permission: 'switchEnvironment',
};

export const CreateUserNotification = {
    title: 'users-add-user-notification-title',
    description: 'users-add-user-notification-description',
};

const genCancel = 'gen-cancel';
const genAdd = 'gen-add';
const genProceed = 'gen-proceed';

export const UserOperatorsModal = {
    title: 'users-select-operators-title',
    description: 'users-add-user-notification-description',
    cancel: genCancel,
    add: genAdd,
};

export const TicketVoidModal = {
    title: 'ticket-void-title',
    description: 'ticket-void-description',
    cancel: genCancel,
    proceed: genProceed,
};

export const ResetPasswordData = {
    title: 'users-actions-reset-password-title',
    button: 'users-actions-reset-password',
    description: 'users-actions-reset-password-description',
};

export const ResetSupervisorBarcodeData = {
    title: 'users-actions-reset-sup-barcode-card-title',
    button: 'users-actions-reset-sup-barcode-card',
    description: 'users-actions-reset-sup-barcode-description',
};

export const ResetSupervisorPinData = {
    title: 'users-actions-reset-sup-pin-title',
    button: 'users-actions-reset-sup-pin',
    description: 'users-actions-reset-sup-pin-description',
};
