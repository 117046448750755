/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import useStyles from 'pages/Reports/Tickets/TicketsReportTable/styles';
import { ORDER_BY_AVOID_REPRINTS_COLUMN, Order, rowsPerPageOptions, APPROVAL_SUPERVISOR_TYPE } from 'const';
import {
    Box,
    Table,
    TableBody,
    TablePagination,
} from '@mui/material';

import { IReportVoidsReprintsModel } from 'api/models/reports';

import VoidsReprintsTableHead from 'pages/Reports/VoidsReprints/VoidsReprintsReportTable/VoidsReprintsReportTableHead/VoidsReprintsReportTableHead';
import VoidsReprintsReportTableRow from 'pages/Reports/VoidsReprints/VoidsReprintsReportTable/VoidsReprintsReportTableRow/VoidsReprintsReportTableRow';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const VoidsReprintsTable = ({
    order,
    orderByColumn,
    page,
    rows,
    rowsCount,
    hideSorting,
    rowsPerPage,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRedirect,
    isMobileView
}: {
    order: Order;
    orderByColumn: ORDER_BY_AVOID_REPRINTS_COLUMN;
    page: number;
    rows: Array<IReportVoidsReprintsModel>;
    rowsCount: number;
    hideSorting?: boolean;
    rowsPerPage: number;
    handleSort: (orderByColumn: ORDER_BY_AVOID_REPRINTS_COLUMN) => void;
    handleChangePage: (event, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRedirect: (documentId: string, approvalTypes: Array<APPROVAL_SUPERVISOR_TYPE>) => void;
    isMobileView: boolean;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const classes = useStyles({});

    return (
        <>
            <Box className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                >
                    <VoidsReprintsTableHead
                        classes={classes}
                        order={order}
                        orderByColumn={orderByColumn}
                        onSort={handleSort}
                        hideSorting={hideSorting}
                    />
                    <TableBody>
                        {rows.map((row, i) => (
                            <VoidsReprintsReportTableRow
                                key={i}
                                row={row}
                                handleRedirect={handleRedirect}
                                isMobileView={isMobileView}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rowsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={translations['hm-table-rows-per-page']}
                ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
            />
        </>);
};

export default VoidsReprintsTable;
