import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useBaseStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableRow/styles';
import useStyles from './styles';

import { IReportFinancialPerformanceRowModel } from 'api/models/reports';
import { headFinancialPerformanceReportColumns } from 'data';
import {
    TableRow,
    TableCell,
    IconButton,
} from '@mui/material';

import FinancialPerformanceReportTableCellContent from '../FinancialPerformanceReportTableCellContent/FinancialPerformanceReportTableCellContent';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const FinancialPerformanceReportTableRow = ({
    row,
}: {
    row: IReportFinancialPerformanceRowModel;
}) => {
    const baseClasses = useBaseStyles();
    const localClasses = useStyles();
    const classes = { ...baseClasses, ...localClasses };
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleButtonClick = () => setIsOpen(false);

        window.addEventListener('buttonClicked', handleButtonClick);

        return () => window.removeEventListener('buttonClicked', handleButtonClick);
    }, []);

    return (
        <>
            <TableRow
                className={classes.row}
                tabIndex={-1}
                onClick={() => setIsOpen(!isOpen)}
            >
                <TableCell
                    className={classes.arrowCell}
                    padding="normal"
                    scope="col"
                    style={{ width: '5%' }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {headFinancialPerformanceReportColumns.map((column) => {
                    const { id } = column;
                    const className = clsx(
                        classes.cell,
                        classes.defaultCell,
                        classes.financialPerformanceCell,
                        classes[`${column.id}Cell`]
                    );

                    return (
                        <TableCell className={className} key={id}>
                            <FinancialPerformanceReportTableCellContent
                                id={id}
                                row={row}
                                className={classes.masterRowCell}
                            />
                        </TableCell>
                    );
                })}
            </TableRow>
            {isOpen &&
                row.childrenFinancialData.map((subentity) => {
                    const className = clsx(
                        classes.cell,
                        classes.defaultCell,
                        classes.financialPerformanceCell,
                        classes[`${subentity.entityName}Cell`]
                    );

                    return (
                        <TableRow
                            key={subentity.entityId}
                            className={clsx(classes.row, classes.notClickableRow)}
                            tabIndex={-1}
                        >
                            <TableCell
                                className={classes.arrowCell}
                                padding="normal"
                                scope="col"
                                style={{ width: '5%' }}
                            >
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    style={{ display: 'none' }}
                                >
                                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            </TableCell>
                            <TableCell
                                className={clsx(className, classes.entityCell)}
                                padding="normal"
                                scope="row"
                                style={{ width: '20%' }}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    <FinancialPerformanceReportTableCellContent
                                        id="entityName"
                                        row={subentity}
                                    />
                                </div>
                            </TableCell>
                            <TableCell className={className}>
                                <FinancialPerformanceReportTableCellContent
                                    id="turnover"
                                    row={subentity}
                                />
                            </TableCell>
                            <TableCell className={className}>
                                <FinancialPerformanceReportTableCellContent
                                    id="winnings"
                                    row={subentity}
                                />
                            </TableCell>
                            <TableCell className={className}>
                                <FinancialPerformanceReportTableCellContent
                                    id="grossWin"
                                    row={subentity}
                                />
                            </TableCell>
                            <TableCell className={className}>
                                <FinancialPerformanceReportTableCellContent
                                    id="winningsPaid"
                                    row={subentity}
                                />
                            </TableCell>
                            <TableCell className={className}>
                                <FinancialPerformanceReportTableCellContent
                                    id="numberOfTotalBets"
                                    row={subentity}
                                />
                            </TableCell>
                            <TableCell className={className}>
                                <FinancialPerformanceReportTableCellContent
                                    id="handlePerBet"
                                    row={subentity}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
        </>
    );
};

export default FinancialPerformanceReportTableRow;
