import React, { useState } from 'react';
import clsx from 'clsx';
import useBaseStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableRow/styles';
import useStyles from './styles';

import { IReportVoidsReprintsModel } from 'api/models/reports';
import { headVoidsReprintsReportColumns } from 'data';
import {
    Box,
    TableRow,
    TableCell,
} from '@mui/material';
import VoidsReprintsReportTableCellContent from 'pages/Reports/VoidsReprints/VoidsReprintsReportTable/VoidsReprintsReportTableCellContent/VoidsReprintsReportTableCellContent';

import { APPROVAL_SUPERVISOR_TYPE } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const VoidsReprintsReportTableRow = ({
    row,
    handleRedirect,
    isMobileView,
}: {
    row: IReportVoidsReprintsModel;
    handleRedirect: (id: string, approvalTypes: Array<APPROVAL_SUPERVISOR_TYPE>) => void;
    isMobileView: boolean;
}) => {
    const baseClasses = useBaseStyles();
    const localClasses = useStyles();
    const [classes] = useState({ ...baseClasses, ...localClasses });

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <TableRow className={classes.row}
            hover
            onClick={() => handleRedirect(row.documentId, row.approvalTypes)}
            tabIndex={-1}
        >
            {!isMobileView
                ? headVoidsReprintsReportColumns.map((column, index) => {
                    const { id } = column;
                    const className = clsx(
                        classes.cell,
                        classes.defaultCell,
                        classes[`${column.id}Cell`],
                    );

                    return (
                        <TableCell className={className}
                            scope={index === 0 ? 'row' : null}
                            key={id}
                        >
                            <VoidsReprintsReportTableCellContent
                                id={id}
                                row={row}
                            />
                        </TableCell>
                    );
                }): (
                    <>
                        <TableCell className={clsx(classes.cell, classes.firstCell)}>
                            <VoidsReprintsReportTableCellContent className={classes.documentId}
                                id="documentId"
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <Box className={clsx(classes.boxWithTitle)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-voids-reprints-executed-on-title']}
                                </span>
                                <VoidsReprintsReportTableCellContent
                                    id="executeDate"
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-voids-reprints-authorized-on-title']}
                                </span>
                                <VoidsReprintsReportTableCellContent
                                    id={'approvalDate'}
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <VoidsReprintsReportTableCellContent
                                id="entityName"
                                row={row}
                            />
                            <VoidsReprintsReportTableCellContent
                                id="supervisorName"
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <VoidsReprintsReportTableCellContent
                                id="betshopName"
                                row={row}
                            />
                            <VoidsReprintsReportTableCellContent
                                id="amount"
                                row={row}
                            />
                        </TableCell>
                    </>
                )
            }
        </TableRow>
    );
};

export default VoidsReprintsReportTableRow;
