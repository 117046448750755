import React from 'react';
import clsx from 'clsx';

import { Button, ButtonGroup } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    buttonGroup: {
        marginRight: theme.spacing(1),
        borderRadius: '4px',
        textTransform: 'none',
        backgroundColor: `${theme.palette.grey[100]}`,
    },
    button: {
        textTransform: 'none',
        whiteSpace: 'nowrap',
        position: 'relative',
        zIndex: 2,
        minWidth: theme.spacing(5),
        padding: `5px ${theme.spacing(1)}`,
        '& > .MuiButton-label': {
            lineHeight: 0.85,
            whiteSpace: 'nowrap'
        }
    },
    icon: {
        position: 'relative',
        zIndex: 1
    }
}));

const ButtonRefresh = ({
    className,
    handleClick,
    disabled = false
}: {
    className?: string;
    handleClick: () => void;
    disabled?: boolean;
}) => {
    const classes = useStyles({});

    return (
        <ButtonGroup
            variant="outlined"
            className={classes.buttonGroup}
            aria-label="split button"
        >
            <Button
                className={clsx(className, classes.button)}
                onClick={handleClick}
                data-a="refresh-button"
                disabled={disabled}
            >
                <RefreshIcon className={classes.icon} />
            </Button>
        </ButtonGroup>
    );
};

export default ButtonRefresh;
