import React, { useContext } from 'react';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import clsx from 'clsx';
import useStyles from './styles';

const VoidsReprintsReportTableCellContent = ({
    className = '',
    id,
    row,
}) => {
    const classes = useStyles();
    const { globalSettings, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);

    const rawValue = row[id];
    let child;

    switch (id) {
        case 'amount':
            child = (
                <span className={className}>
                    {formatNumberToCurrencyString(
                        rawValue,
                        numberStyles(globalSettings?.settings?.Currency),
                        globalSettings?.settings?.CurrencySign,
                        globalSettings?.settings?.CurrencyFormat
                    )}
                </span>
            );
            break;
        case 'approvalDate':
        case 'executeDate':
            const formattedDate = rawValue?.length
                ? formatDateByPattern(new Date(formatToIsoDateString(rawValue)), dateTimeFormat, timeZone)
                : '';

            child = (
                <span className={clsx(classes.approvalDateWrap, className)}>
                    <span className={classes.approvalDate}>
                        {formattedDate}
                    </span>
                </span>);
            break;
        default:
            child = <span className={className}>{rawValue}</span>;
    }

    return child;
};

export default VoidsReprintsReportTableCellContent;
