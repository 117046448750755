import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react';

import clsx from 'clsx';

import { useHistory, useLocation } from 'react-router';

import { Box, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';

import { IFilterListItem } from 'const';
import { supervisorApprovalVoidsReprintsFilterList } from 'data';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import NoResultsFound from 'components/NoResultsFound';
import SearchbyNumber from 'components/SearchByNumber';
import VoidsReprintsReportFilters from 'pages/Reports/VoidsReprints/VoidsReprintsReportFilters/VoidsReprintsReportFilters';
import VoidsReprintsReportTable from 'pages/Reports/VoidsReprints/VoidsReprintsReportTable/VoidsReprintsReportTable';
import DateRangeLegend from 'components/DateRangeLegend';
import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';
import ButtonRefresh from 'components/Buttons/ButtonRefresh';

import { GlobalContext } from 'context/globalContext';

import { INetworkBetshop } from 'api/models/network-management';

import {
    startOfDay,
    endOfDay,
} from 'utils/formatDate';

import {
    IReportVoidsReprintsModel,
    IVoidsReprintsBaseResponse,
    IAlertMessage
} from 'api/models/reports';

import Api from 'api/Api';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';

import {
    Order,
    rowsPerPageOptions,
    EXCEL_EXPORT_ERROR_ENUM,
    REPORTS_NAMES,
    ORDER_BY_AVOID_REPRINTS_COLUMN,
    ORDER_BY_AVOID_REPRINTS_COLUMN_NAMES,
    APPROVAL_SUPERVISOR_TYPE,
    initialAlertMessage,
    localStorageFiltersKeys,
} from 'const';

import formatStr from 'utils/formatStr';

import { setLanguageCookie } from 'utils/cookieMethods';

import {
    IVoidsReprintsReportBaseFilterList,
    IVoidsReprintsReportFilterList,
    documentIdSearchValidator,
    voidsReprintsReportFilterListInitial as filterListInitial,
    IVoidsReprintsReportInitState,
} from 'pages/Reports/business';

import {
    generateVoidsReprintsPayload,
} from 'pages/Reports/networkHelpers';

import { IApprovalExecutorUsersResponseModel } from 'api/models/reports';

import { mqttClient, initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';

import useStyles from 'pages/Reports/Tickets/styles';
import useStylesLocal from './styles';
import { CancelablePromise } from 'api/services/_BaseApi';

import { GlobalContextModel } from 'api/models/general';

import { useWidth } from 'utils/customHooks';
import { getFromLocalStorage } from 'utils/devUtils';

type IApiRequests = {
    getAllBetshops: any | CancelablePromise<Array<INetworkBetshop>>;
    voided: null | CancelablePromise<IVoidsReprintsBaseResponse>;
    reprinted: null | CancelablePromise<IVoidsReprintsBaseResponse>;
    voidedById: null | CancelablePromise<IVoidsReprintsBaseResponse>;
    reprintedById: null | CancelablePromise<IVoidsReprintsBaseResponse>;
    getApprovalExecutorUsers: null | CancelablePromise<IApprovalExecutorUsersResponseModel>;
}

enum ITabs {
    voided,
    reprinted
}

const apiRequests: IApiRequests = {
    getAllBetshops: null,
    voided: null,
    reprinted: null,
    voidedById: null,
    reprintedById: null,
    getApprovalExecutorUsers: null,
};

let pendingGetExport = false;
let lastSearchResultDateVoided;
let lastSearchResultDateReprinted;

const VoidsReprintsReport = () => {
    const { pathname } = useLocation<IVoidsReprintsReportInitState>();
    const history = useHistory();
    const classes = useStyles({});
    const classesLocal = useStylesLocal({});
    const { globalSettings, translations, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isMobileView = useWidth() === 'xs';

    const voidsReprintsReportMaxRangeInDays = globalSettings?.settings?.ExcelExportSettings?.VoidsReprintsMaxRangeDays != null
        ? globalSettings?.settings?.ExcelExportSettings?.VoidsReprintsMaxRangeDays
        : globalSettings?.settings?.ExcelExportSettings?.DefaultReportMaxRangeDays;
    const voidsReprintsReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * voidsReprintsReportMaxRangeInDays;

    const filtersListsInitial: IVoidsReprintsReportBaseFilterList = {
        betShops: [],
        approvalTypes: [],
        supervisorsIds: [],
        entitiesEmpIds: [],
    };

    const { reportVoidsReprintsExport } = permissions;

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.voidsReprintsReportFilters);

    const [filtersLists, setFiltersLists] = useState({ ...filtersListsInitial });

    const dateInTimeZone = new Date(new Date().toLocaleString('en', { timeZone }));
    const fromDate = startOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );
    const toDate = endOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );

    const filterListInitialWithDate = { ...filterListInitial, fromDate, toDate };

    const filterListStorageInitial = {
        ...localStorageFilters ? localStorageFilters : filterListInitial, fromDate, toDate
    };

    // applied filters (as reference values when closing filters drawer for resetting if it is not applied)
    const [appliedFilters, setAppliedFilters] = useState({ ...filterListStorageInitial });

    const [isAscendingOrder, setIsAscendingOrder] = useState(false);

    const [areRowsLoaded, setRowsLoaded] = useState(false);
    const [areFiltersListsLoaded, setFiltersListsLoaded] = useState(false);
    const isLoaded = areRowsLoaded && areFiltersListsLoaded;

    const isReadyToShowRef = useRef(false);

    isLoaded && (isReadyToShowRef.current = true);

    const [rowsVoided, setRowsVoided] = useState<Array<IReportVoidsReprintsModel>>([]);
    const [rowsReprinted, setRowsReprinted] = useState<Array<IReportVoidsReprintsModel>>([]);

    const [pageVoided, setPageVoided] = useState(0);
    const [pageReprinted, setPageReprinted] = useState(0);

    const previousRowsPerPageRef= useRef(rowsPerPageOptions[0]);
    const [rowsPerPage, setRowsPerPage] = useState(previousRowsPerPageRef.current);
    const scrollDownRef = useRef(false);

    const [rowsVoidedCount, setRowsVoidedCount] = useState(0);
    const [rowsReprintedCount, setRowsReprintedCount] = useState(0);

    const [searchText, setSearchText] = useState<string>('');
    const [searchResetKey, setSearchResetKey] = useState(Math.random());
    const [invalidSearchValue, setInvalidSearchValue] = useState(false);
    const [isSearchedByDocumentID, setIsSearchedByDocumentID] = useState(false);
    const [documentIdFromSearch, setDocumentIdFromSearch] = useState<string>(null);

    const [orderByColumn, setOrderByColumn] = useState<ORDER_BY_AVOID_REPRINTS_COLUMN>(ORDER_BY_AVOID_REPRINTS_COLUMN_NAMES.approvalDate);
    const [alertMessage, setAlertMessage] = useState<IAlertMessage>({ ...initialAlertMessage });

    const [activeTab, setActiveTab] = useState<ITabs>(ITabs.voided);
    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    const ExportToExcelState = useContext(ExportToExcelContext);
    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.voidsReprints];

    isFailedExportToExcel && setAlertMessage({
        show: true,
        message: excelExportError === EXCEL_EXPORT_ERROR_ENUM.NoData
            ? translations['emp-transaction-nodata-error']
            : translations['general-error']
    });

    const loadAdditionData = async () => {
        try {
            apiRequests.getAllBetshops = Api.NetworkManagement.GetBetshopsLite();
            apiRequests.getApprovalExecutorUsers = Api.VoidsReprintsReport.GetApprovalExecutorUsers({ betShopIds: [] });

            const betshopsResult: Array<INetworkBetshop> = (await apiRequests.getAllBetshops)?.[globalSettings?.user?.ClientId];
            const { approvalUsers, executorUsers }: IApprovalExecutorUsersResponseModel = await apiRequests.getApprovalExecutorUsers;

            const approvalUsersItems: Array<IFilterListItem> = approvalUsers
                .map(({ id, userName: name }) => ({ id, name }))
                .sort((a, b) => a.name.localeCompare(b.name));

            const executorUsersItems: Array<IFilterListItem> = executorUsers
                .map(({ id, userName: name }) => ({ id, name }))
                .sort((a, b) => a.name.localeCompare(b.name));

            const betShopItems = betshopsResult
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => a.name.localeCompare(b.name));

            setFiltersLists({
                approvalTypes: supervisorApprovalVoidsReprintsFilterList.map(({ id, name }) => ({ id, name: translations[name] })),
                betShops: structuredClone(betShopItems),
                entitiesEmpIds: structuredClone(executorUsersItems),
                supervisorsIds: structuredClone(approvalUsersItems)
            });
        } catch (error) {
            throwNetworkError(error);
        } finally {
            setFiltersListsLoaded(true);
            loadDataHandler();
        }
    };

    const checkResultsForActiveTab = (countVoided: number, countReprinted: number): ITabs => {
        if (countVoided === 0 && countReprinted === 0) {
            return ITabs.voided;
        }

        if (activeTab === ITabs.voided) {
            //if no results in voided tab, switch to reprinted tab
            if (!countVoided) {
                return ITabs.reprinted;
            }
        } else {
            //if no results in reprinted tab, switch to voided tab
            if (!countReprinted) {
                return ITabs.voided;
            }
        }

        return activeTab;
    };

    const loadVoidedData = async ({
        newFilters = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newPageVoided = newRowsPerPage === rowsPerPage ? pageVoided : 0,
        newIsAscendingOrder = isAscendingOrder,
        newOrderByColumn = orderByColumn,
    }: {
        newFilters?: IVoidsReprintsReportFilterList;
        newRowsPerPage?: number;
        newPageVoided?: number;
        newIsAscendingOrder?: boolean;
        newOrderByColumn?: ORDER_BY_AVOID_REPRINTS_COLUMN;
        skipCheckingActiveTab?: boolean;
    } = {}) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        const payloadVoided = generateVoidsReprintsPayload(
            newFilters,
            newPageVoided,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            timeZone,
            null,
            true
        );

        apiRequests.voided = Api.VoidsReprintsReport.SearchVoidedTickets(payloadVoided);

        try {
            const {
                searchResult,
                count,
                pageNumber,
                lastSearchResultDate
            } = await apiRequests.voided;

            setPageVoided(pageNumber - 1);
            setRowsVoidedCount(count);
            setRowsVoided(searchResult);
            setIsSearchedByDocumentID(false);
            setRowsLoaded(true);

            lastSearchResultDateVoided = lastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
        } finally {
            setRowsLoaded(true);
        }
    };

    const loadReprintedData = async ({
        newFilters = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newPageReprinted = newRowsPerPage === rowsPerPage ? pageReprinted : 0,
        newIsAscendingOrder = isAscendingOrder,
        newOrderByColumn = orderByColumn,
    }: {
        newFilters?: IVoidsReprintsReportFilterList;
        newRowsPerPage?: number;
        newPageReprinted?: number;
        newIsAscendingOrder?: boolean;
        newOrderByColumn?: ORDER_BY_AVOID_REPRINTS_COLUMN;
        skipCheckingActiveTab?: boolean;
    } = {}) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        const payloadReprint = generateVoidsReprintsPayload(
            newFilters,
            newPageReprinted,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            timeZone,
            null,
            false
        );

        apiRequests.reprinted = Api.VoidsReprintsReport.SearchReprintedDocuments(payloadReprint);

        try {
            const {
                searchResult,
                count,
                pageNumber,
                lastSearchResultDate
            } = await apiRequests.reprinted;

            setPageReprinted(pageNumber - 1);
            setRowsReprintedCount(count);
            setRowsReprinted(searchResult);
            setIsSearchedByDocumentID(false);
            setRowsLoaded(true);

            lastSearchResultDateReprinted = lastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
        } finally {
            setRowsLoaded(true);
        }
    };

    const loadDataHandler = async ({
        newFilters = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newPageVoided = newRowsPerPage === rowsPerPage ? pageVoided : 0,
        newPageReprinted = newRowsPerPage === rowsPerPage ? pageReprinted : 0,
        newIsAscendingOrder = isAscendingOrder,
        newOrderByColumn = orderByColumn,
        skipCheckingActiveTab = false
    }: {
        newFilters?: IVoidsReprintsReportFilterList;
        newRowsPerPage?: number;
        newPageVoided?: number;
        newPageReprinted?: number;
        newIsAscendingOrder?: boolean;
        newOrderByColumn?: ORDER_BY_AVOID_REPRINTS_COLUMN;
        skipCheckingActiveTab?: boolean;
    } = {}) => {
        setSearchText('');
        setSearchResetKey(Math.random());
        setInvalidSearchValue(false);
        setRowsLoaded(false);

        const payloadVoided = generateVoidsReprintsPayload(
            newFilters,
            newPageVoided,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            timeZone,
            null,
            true
        );

        const payloadReprint = generateVoidsReprintsPayload(
            newFilters,
            newPageReprinted,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            timeZone,
            null,
            false
        );

        apiRequests.voided = Api.VoidsReprintsReport.SearchVoidedTickets(payloadVoided);
        apiRequests.reprinted = Api.VoidsReprintsReport.SearchReprintedDocuments(payloadReprint);

        try {
            const {
                searchResult: searchResultVoided,
                count: countVoided,
                pageNumber: pageNumberVoided,
                lastSearchResultDate: voidedLastSearchResultDate
            } = await apiRequests.voided;

            const {
                searchResult: searchResultReprinted,
                count: countReprinted,
                pageNumber: pageNumberReprinted,
                lastSearchResultDate: reprintedLastSearchResultDate
            } = await apiRequests.reprinted;

            let newActiveTab: ITabs = activeTab;

            if (!skipCheckingActiveTab) {
                //check if there are any approvalTypes filters applied
                if (newFilters.approvalTypes.length) {
                    if (newFilters.approvalTypes.some(({ id }) => id === APPROVAL_SUPERVISOR_TYPE.TicketVoid)) {
                        newActiveTab = ITabs.voided;
                    } else {
                        newActiveTab = ITabs.reprinted;
                    }
                } else {
                    newActiveTab = checkResultsForActiveTab(countVoided, countReprinted);
                }
            }

            setActiveTab(newActiveTab);
            setPageVoided(pageNumberVoided - 1);
            setPageReprinted(pageNumberReprinted - 1);
            setRowsVoidedCount(countVoided);
            setRowsReprintedCount(countReprinted);
            setRowsVoided(searchResultVoided);
            setRowsReprinted(searchResultReprinted);
            setIsSearchedByDocumentID(false);
            setRowsLoaded(true);

            lastSearchResultDateVoided = voidedLastSearchResultDate;
            lastSearchResultDateReprinted = reprintedLastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
        } finally {
            setRowsLoaded(true);
        }
    };

    const searchByIdVoidedHandler = async (documentId: string, newPage: number = pageVoided) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        const payload = {
            documentId,
            pageNumber: newPage + 1,
            pageSize: rowsPerPage,
        };

        apiRequests.voidedById = Api.VoidsReprintsReport.SearchVoidedTicketById(payload);

        try {
            const {
                searchResult: searchResultVoided,
                count: countVoided,
                pageNumber: pageNumberVoided,
                lastSearchResultDate: voidedLastSearchResultDate
            } = await apiRequests.voidedById;

            setPageVoided(pageNumberVoided - 1);
            setRowsVoidedCount(countVoided);
            setRowsVoided(searchResultVoided);
            setIsSearchedByDocumentID(true);
            setRowsLoaded(true);
            setDocumentIdFromSearch(documentId);

            lastSearchResultDateVoided = voidedLastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
            setRowsLoaded(true);
        }
    };

    const searchByIdReprintedHandler = async (documentId: string, newPage: number = pageReprinted) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        const payload = {
            documentId,
            pageNumber: newPage + 1,
            pageSize: rowsPerPage,
        };

        apiRequests.reprintedById = Api.VoidsReprintsReport.SearchReprintedDocumentById(payload);

        try {
            const {
                searchResult: searchResultReprinted,
                count: countReprinted,
                pageNumber: pageNumberReprinted,
                lastSearchResultDate: reprintedLastSearchResultDate
            } = await apiRequests.reprintedById;

            setPageReprinted(pageNumberReprinted - 1);
            setRowsReprintedCount(countReprinted);
            setRowsReprinted(searchResultReprinted);
            setIsSearchedByDocumentID(true);
            setRowsLoaded(true);
            setDocumentIdFromSearch(documentId);

            lastSearchResultDateReprinted = reprintedLastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
            setRowsLoaded(true);
        }
    };

    const searchByIdHandler = async (documentId: string) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        const payload = {
            documentId,
            pageSize: rowsPerPage,
        };

        const payloadVoided = { ...payload, pageNumber: pageVoided + 1 };
        const payloadReprint = { ...payload, pageNumber: pageReprinted + 1 };

        apiRequests.voidedById = Api.VoidsReprintsReport.SearchVoidedTicketById(payloadVoided);
        apiRequests.reprintedById = Api.VoidsReprintsReport.SearchReprintedDocumentById(payloadReprint);

        try {
            const {
                searchResult: searchResultVoided,
                count: countVoided,
                pageNumber: pageNumberVoided,
                lastSearchResultDate: voidedLastSearchResultDate
            } = await apiRequests.voidedById;

            const {
                searchResult: searchResultReprinted,
                count: countReprinted,
                pageNumber: pageNumberReprinted,
                lastSearchResultDate: reprintedLastSearchResultDate
            } = await apiRequests.reprintedById;

            const newActiveTab = checkResultsForActiveTab(countVoided, countReprinted);

            setActiveTab(newActiveTab);
            setPageVoided(pageNumberVoided - 1);
            setPageReprinted(pageNumberReprinted - 1);
            setRowsVoidedCount(countVoided);
            setRowsReprintedCount(countReprinted);
            setRowsVoided(searchResultVoided);
            setRowsReprinted(searchResultReprinted);
            setIsSearchedByDocumentID(true);
            setRowsLoaded(true);
            setDocumentIdFromSearch(documentId);

            lastSearchResultDateVoided = voidedLastSearchResultDate;
            lastSearchResultDateReprinted = reprintedLastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
            setRowsLoaded(true);
        }
    };

    useLayoutEffect(() => {
        if (scrollDownRef.current) {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;

            scrollDownRef.current = false;
        }
    }, [rowsVoided, rowsReprinted]);

    useEffect(() => {
        loadAdditionData();
    }, [translations]);

    useEffect(() => {
        scrollDownRef.current = previousRowsPerPageRef.current !== rowsPerPage;
        previousRowsPerPageRef.current = rowsPerPage;
    }, [rowsPerPage]);

    const handleChangePage = (event, newPage: number) => {
        cancelRequests(apiRequests);

        activeTab === ITabs.voided
            ? searchText
                ? searchByIdVoidedHandler(searchText, newPage)
                : loadVoidedData({ newPageVoided: newPage })
            : searchText
                ? searchByIdReprintedHandler(searchText, newPage)
                : loadReprintedData({ newPageReprinted: newPage });
    };

    const handleSort = (clickedOrderByColumn: ORDER_BY_AVOID_REPRINTS_COLUMN) => {
        let newAscendingOrder = isAscendingOrder;
        let newOrderByColumn = clickedOrderByColumn;

        if (clickedOrderByColumn === orderByColumn) {
            newAscendingOrder = !isAscendingOrder;
            setIsAscendingOrder(newAscendingOrder);
        } else {
            setOrderByColumn(newOrderByColumn);
        }

        cancelRequests(apiRequests);
        loadDataHandler({ newIsAscendingOrder: newAscendingOrder, newOrderByColumn });
    };

    const searchTextSubmitHandler = (value: string) => {
        if (value.length) {
            setAppliedFilters({ ...filterListInitialWithDate });

            const isValid = documentIdSearchValidator(value);

            setInvalidSearchValue(!isValid);

            if (isValid) {
                setSearchText(value);
                searchByIdHandler(value);
            } else {
                setRowsVoided([]);
                setRowsReprinted([]);
                setRowsVoidedCount(0);
                setRowsReprintedCount(0);
            }
        } else {
            setInvalidSearchValue(false);
            setIsSearchedByDocumentID(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        cancelRequests(apiRequests);

        loadDataHandler({ newRowsPerPage: event.target.value });
    };

    const handleFiltersApply = (filters) => {
        if (isSaveFilters) {
            const filtersToStorage = {};

            for (let key in filterListInitial) {
                if (filterListInitial.hasOwnProperty(key)) {
                    filtersToStorage[key] = filters[key];
                }
            }

            localStorage.setItem(localStorageFiltersKeys.voidsReprintsReportFilters, JSON.stringify(filtersToStorage));
        }

        cancelRequests(apiRequests);

        setAlertMessage({ ...alertMessage, show: false });
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.voidsReprints]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });
        setAppliedFilters(filters);

        loadDataHandler({ newFilters: { ...filters }, newPageVoided: 0, newPageReprinted: 0 });
    };

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.voidsReprintsReportFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    const resetToDefault = () => {
        handleFiltersApply({ ...filterListInitialWithDate });
        setDocumentIdFromSearch(null);
    };

    const handleClearFilters = () => {
        localStorage.removeItem(localStorageFiltersKeys.voidsReprintsReportFilters);
        setAppliedFilters({ ...filterListInitialWithDate });
        setIsSaveFilters(false);
        loadDataHandler({ newFilters: { ...filterListInitialWithDate } });
    };

    const handleLoadingExcel = async () => {
        if ((activeTab === ITabs.voided && rowsVoided.length === 0) || (activeTab === ITabs.reprinted && rowsReprinted.length === 0)) {
            setAlertMessage({ show: true, message: translations['emp-transaction-nodata-error'] });
        } else if (appliedFilters.toDate.getTime() - appliedFilters.fromDate.getTime() > voidsReprintsReportMaxRangeInMilliseconds) {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-transaction-export-to-excel-error-days'],
                    { 'days': voidsReprintsReportMaxRangeInDays }
                )}`
            });
        } else {
            let payload = null;

            if (activeTab === ITabs.voided) {
                payload = generateVoidsReprintsPayload(
                    appliedFilters,
                    rowsPerPage,
                    pageVoided,
                    isAscendingOrder,
                    orderByColumn,
                    timeZone,
                    lastSearchResultDateVoided,
                    true,
                    documentIdFromSearch ?? documentIdFromSearch
                );
            }

            if (activeTab === ITabs.reprinted) {
                payload = generateVoidsReprintsPayload(
                    appliedFilters,
                    rowsPerPage,
                    pageReprinted,
                    isAscendingOrder,
                    orderByColumn,
                    timeZone,
                    lastSearchResultDateReprinted,
                    false,
                    documentIdFromSearch ?? documentIdFromSearch
                );
            }

            if (!pendingGetExport) {
                pendingGetExport = true;
                Api.VoidsReprintsReport.GetExport(
                    setLanguageCookie({ ...payload, reportType: EXPORT_REPORT_TYPE.VoidsReprints }, globalSettings.languages, 'languageId'))
                    .then(res => {
                        const {
                            jwtToken,
                            reportId
                        } = res;

                        if (jwtToken) {
                            !mqttClient?.connected && initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);

                            subscribeToMqtt({
                                topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                onMessageHandler: (response) => onMessageExcelHandler(
                                    response,
                                    reportId,
                                    globalSettings.settings.MqttSettigs.ReportsExportStatusTopic,
                                    REPORTS_NAMES.voidsReprints)
                            });
                        }
                    }).catch((error) => {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.voidsReprints]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                        throwNetworkError(error);
                    }).finally(() => pendingGetExport = false);
            }
        }
    };

    const handleHideAlert = () => {
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.voidsReprints]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });

        setAlertMessage({ ...alertMessage, show: false });
    };

    const handleRedirect = (
        id: string,
        approvalTypes: Array<APPROVAL_SUPERVISOR_TYPE>
    ) => {

        const state: IVoidsReprintsReportInitState = {
            filtersLists,
            appliedFilters,
            isAscendingOrder,
            rowsPerPage,
            // rowsCount,
            rowsVoided,
            rowsReprinted,
            pageVoided,
            pageReprinted,
            isSearchedByDocumentID,
            searchText,
        };

        if (activeTab === ITabs.voided || !approvalTypes.includes(APPROVAL_SUPERVISOR_TYPE.VoucherReprint)) {
            history.replace({
                pathname: '/reports/voids-reprints',
                state
            });

            history.push({
                pathname: `/reports/tickets/${id}`,
                state: {
                    pathFrom: pathname
                }
            });
        } else {
            history.replace({
                pathname: '/reports/voids-reprints',
                state
            });

            history.push({
                pathname: `/reports/vouchers/${id}`,
                state: {
                    pathFrom: pathname
                }
            });
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: ITabs) => {
        setActiveTab(newValue);
    };

    const handleRefreshData = () => {
        loadDataHandler({ newPageVoided: 0, newPageReprinted: 0, skipCheckingActiveTab: true });
    };

    const VoidsReprintsReportFiltersWithParams = (
        <VoidsReprintsReportFilters
            filtersLists={filtersLists}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleFiltersApply}
            filterListInitialWithDate={filterListInitialWithDate}
            handleClearFilters={handleClearFilters}
            handleSaveFilters={handleSaveFilters}
            isSaveFilters={isSaveFilters}
        />
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={clsx(classes.searchWrap, classesLocal.searchWrap)}>
                    <SearchbyNumber
                        initValue={searchText}
                        searchResetKey={searchResetKey}
                        placeholder={translations['emp-reports-voids-reprints-search']}
                        onSubmit={searchTextSubmitHandler}
                        onReset={resetToDefault}
                        searchValidator={documentIdSearchValidator}
                    />
                </div>
                <div className={classes.titleWrap}>
                    <Typography className={classes.title}
                        component="h1"
                        noWrap
                    >
                        {translations['gen-voids-reprints-report-title']}
                    </Typography>
                    {isMobileView &&
                        <div className={classes.actions}>
                            <ButtonRefresh handleClick={handleRefreshData} />
                            {VoidsReprintsReportFiltersWithParams}
                            {reportVoidsReprintsExport &&
                                <div style={{ marginLeft: '8px' }}>
                                    <ButtonWithLinearProgress
                                        label={translations['emp-transaction-export-to-excel'] as string}
                                        value={valueExportToExcel}
                                        handleLoading={handleLoadingExcel}
                                        loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {isMobileView && (
                    <DateRangeLegend
                        translationKey="emp-reports-results-range-title"
                        fromDate={appliedFilters.fromDate}
                        toDate={appliedFilters.toDate}
                    />)}
            </div>
            {<Divider className={classes.divider} />}
            <div className={classes.paper}>
                <BackdropSpinner open={!isLoaded} />
                {isReadyToShowRef.current && (
                    <>
                        {!isMobileView && (
                            <div className={classes.resultsHeader}>
                                {!isSearchedByDocumentID && (
                                    <DateRangeLegend
                                        translationKey="emp-reports-results-range-title"
                                        fromDate={appliedFilters.fromDate}
                                        toDate={appliedFilters.toDate}
                                    />)}
                                <div className={classes.options}>
                                    <ButtonRefresh handleClick={handleRefreshData} />
                                    {VoidsReprintsReportFiltersWithParams}
                                    {reportVoidsReprintsExport &&
                                        <div style={{ marginLeft: '8px' }}>
                                            <ButtonWithLinearProgress
                                                label={translations['emp-transaction-export-to-excel'] as string}
                                                value={valueExportToExcel}
                                                handleLoading={handleLoadingExcel}
                                                loading={isLoadingExportToExcel}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className={classes.alertWrapper}>
                            <AlertMessageFullWidth
                                label={alertMessage.message}
                                show={alertMessage.show}
                                handleHideAlert={handleHideAlert}
                            >
                                {
                                    translations['gen-dismiss']
                                }
                            </AlertMessageFullWidth>
                        </div>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons={false}
                        >
                            <Tab
                                className={classesLocal.tab}
                                value={ITabs.voided}
                                label={<span data-a={'voided-tab-label'}>{`${translations['emp-reports-voids-reprints-tab-voids']} ${rowsVoidedCount}`}</span>}
                                data-a={'voided-tab'}
                            />
                            <Tab
                                className={classesLocal.tab}
                                value={ITabs.reprinted}
                                label={<span data-a={'reprinted-tab-label'}>{`${translations['emp-reports-voids-reprints-tab-reprints']} ${rowsReprintedCount}`}</span>}
                                data-a={'reprinted-tab'}
                            />
                        </Tabs>
                        <Box className={classes.resultsWrapper}>
                            {((
                                (rowsVoided.length && activeTab === ITabs.voided) || (rowsReprinted.length && activeTab === ITabs.reprinted)) &&
                                    !invalidSearchValue)
                                ? (
                                    <VoidsReprintsReportTable
                                        page={activeTab === ITabs.voided ? pageVoided : pageReprinted}
                                        rows={activeTab === ITabs.voided ? rowsVoided : rowsReprinted}
                                        rowsCount={activeTab === ITabs.voided ? rowsVoidedCount : rowsReprintedCount}
                                        hideSorting={isSearchedByDocumentID}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        handleSort={handleSort}
                                        handleRedirect={handleRedirect}
                                        order={isAscendingOrder ? Order.ascending : Order.descending}
                                        orderByColumn={orderByColumn}
                                        isMobileView={isMobileView}
                                    />
                                )
                                : (
                                    <Box paddingTop={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <NoResultsFound
                                                    onResetClick={resetToDefault}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default VoidsReprintsReport;
