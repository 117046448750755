import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IVoidsReprintsRequestPayload,
    IVoidsReprintsBaseResponse,
    IReportingApiBaseRequest,
    IApprovalExecutorUsersResponseModel,
    IVoidsReprintsByIdRequestPayload
} from 'api/models/reports';

import {
    IVoidsReprintsReportExportPayload,
    IReportsExportModel,
} from 'api/models/reportsExport';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class VoidsReprintsReportApi extends BaseApi {
    SearchVoidedTickets(payload: IVoidsReprintsRequestPayload) {
        return this.QueryEmpBff<IVoidsReprintsBaseResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VoidsReprintsReport.SearchVoidedTickets, this.QueryMethods.POST);
    }

    SearchReprintedDocuments(payload: IVoidsReprintsRequestPayload) {
        return this.QueryEmpBff<IVoidsReprintsBaseResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VoidsReprintsReport.SearchReprintedDocuments, this.QueryMethods.POST);
    }

    SearchVoidedTicketById(payload: IVoidsReprintsByIdRequestPayload) {
        return this.QueryEmpBff<IVoidsReprintsBaseResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VoidsReprintsReport.SearchVoidedByTicketId, this.QueryMethods.POST);
    }

    SearchReprintedDocumentById(payload: IVoidsReprintsByIdRequestPayload) {
        return this.QueryEmpBff<IVoidsReprintsBaseResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VoidsReprintsReport.SearchReprintedByDocumentId, this.QueryMethods.POST);
    }

    GetApprovalExecutorUsers(payload: IReportingApiBaseRequest) {
        return this.QueryEmpBff<IApprovalExecutorUsersResponseModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VoidsReprintsReport.GetApprovalExecutorUsers, this.QueryMethods.POST);
    }

    GetExport(payload: IVoidsReprintsReportExportPayload) {
        return this.QueryEmpBff<IReportsExportModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.VoidsReprintsReportExport.GetExport,
            this.QueryMethods.POST
        );
    }
}
