import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
    approvalDateWrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',

        [breakpoints.down('sm')]: {
            marginRight: 'auto',
            padding: 0,
        }
    },
    approvalDate: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

export default useStyles;
