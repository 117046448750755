import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }: Theme) => ({
    notClickableRow: {
        cursor: 'default',
    },
    betshopName: {
        [breakpoints.down('sm')]: {
            color: palette.grey[700],
            whiteSpace: 'initial',
        }
    },
    inlineTitle: {
        [breakpoints.down('sm')]: {
            marginRight: spacing(4/8),
            fontWeight: typography.fontWeightMedium,
        }
    },
    documentId: {
        [breakpoints.down('sm')]: {
            marginBottom: spacing(0.5),
            fontSize: '1.1rem',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    userNameWrapper: {
        [breakpoints.down('sm')]: {
            marginRight: 'auto',
        },
    },
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: spacing(20),
        padding: spacing(2, 1),

        [breakpoints.down('sm')]: {
            display: 'flex',
            maxWidth: 'initial',
            border: 'none',
            marginTop: spacing(4/8),
            padding: 0,
            whiteSpace: 'initial',
            justifyContent: 'space-between',

            '&:last-child': {
                '&:empty': {
                    display: 'none',
                },
            }
        },
    },
    boxWithTitle: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 500,
        color: '#666',
    },
}));

export default useStyles;
