import React, { useContext, useEffect, useRef, useState } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import NoResultsFound from 'components/NoResultsFound';
import DateRangeLegend from 'components/DateRangeLegend';
import ButtonRefresh from 'components/Buttons/ButtonRefresh';

import { GlobalContext, globalSettings } from 'context/globalContext';

import { IBetshopListLiteResult } from 'api/models/network-management';

import {
    IAlertMessage,
    IFinancialPerformanceSearchResponse,
    IPointsOfSaleModel,
    IReportFinancialPerformanceRowModel,
} from 'api/models/reports';

import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import {
    Order,
    REPORTS_NAMES,
    generalFinancialDataTranslations,
    ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN,
    ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES,
    ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_ENTITY_NAME,
    initialAlertMessage,
    localStorageFiltersKeys,
    EXCEL_EXPORT_ERROR_ENUM,
} from 'const';

import { startOfDay, endOfDay } from 'utils/formatDate';

import { setLanguageCookie } from 'utils/cookieMethods';

import {
    IFinancialPerformanceReportBaseFilterList,
    IFinancialPerformanceReportFilterList,
    financialPerformanceReportFilterListInitial as filterListInitial,
} from 'pages/Reports/business';

import FinancialPerformanceReportFilters from './FinancialPerformanceReportFilters/FinancialPerformanceReportFilters';
import FinancialPerformanceReportTable from './FinancialPerformanceReportTable/FinancialPerformanceReportTable';

import {
    generateFinancialPerformanceSearchPayload,
    getAllBetshops,
    getEntities,
} from 'pages/Reports/networkHelpers';

import { mqttClient, initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';

import { CancelablePromise } from 'api/services/_BaseApi';

import { GlobalContextModel } from 'api/models/general';
import useStyles from './styles';
import FinancialPerformanceReportList from './FinancialPerformanceReportList/FinancialPerformanceReportList';
import Api from 'api/Api';
import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { sortNestedArrayByOrder } from 'utils/arrayMethods';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';
import formatStr from 'utils/formatStr';
import { useWidth } from 'utils/customHooks';
import { getFromLocalStorage } from 'utils/devUtils';
import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';

type IApiRequests = {
  getAllBetshops: any | CancelablePromise<IBetshopListLiteResult>;
  getFinancialPerformancePage: null | CancelablePromise<IFinancialPerformanceSearchResponse>;
  getCashiersList: null | CancelablePromise<IPointsOfSaleModel>;
  getExport: null | CancelablePromise<any>;
};

const apiRequests: IApiRequests = {
    getAllBetshops: null,
    getFinancialPerformancePage: null,
    getCashiersList: null,
    getExport: null,
};

let pendingGetExport = false;

const FinancialPerformanceReport = () => {
    const classes = useStyles({});
    const { translations, timeZone, permissions }: GlobalContextModel =
    useContext(GlobalContext);

    const { reportFinancialPerformanceExport } = permissions;

    const ExportToExcelState = useContext(ExportToExcelContext);
    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.financialPerformance];

    const isMobileView = useWidth() === 'xs';

    const financialPerformanceReportMaxRangeInMilliseconds =
    1000 *
    60 *
    60 *
    24 *
    globalSettings?.settings?.FinancialPerformanceReportSettings?.MaxRangeDays;

    const financialPerformanceReportMaxRangeINDays = globalSettings?.settings?.ExcelExportSettings?.FinancialPerformanceReportMaxRangeDays != null
        ? globalSettings?.settings?.ExcelExportSettings?.FinancialPerformanceReportMaxRangeDays
        : globalSettings?.settings?.ExcelExportSettings?.DefaultReportMaxRangeDays;
    const financialPerformanceExcelReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * financialPerformanceReportMaxRangeINDays;

    const filtersListsInitial: IFinancialPerformanceReportBaseFilterList = {
        betShops: [],
        terminals: [],
        cashiers: [],
    };

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.financialPerformanceReportFilters);

    const [filtersLists, setFiltersLists] = useState({ ...filtersListsInitial });

    const [alertMessage, setAlertMessage] = useState<IAlertMessage>({
        ...initialAlertMessage,
    });

    isFailedExportToExcel && setAlertMessage({
        show: true,
        message: excelExportError === EXCEL_EXPORT_ERROR_ENUM.NoData
            ? translations['emp-transaction-nodata-error']
            : translations['general-error']
    });

    const dateInTimeZone = new Date(
        new Date().toLocaleString('en', { timeZone })
    );
    const fromDate = startOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );
    const toDate = endOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );
    const filterListInitialWithDate = { ...filterListInitial, fromDate, toDate };
    const filterListStorageInitial = {
        ...localStorageFilters ? localStorageFilters : filterListInitial, fromDate, toDate
    };

    // applied filters (as reference values when closing filters drawer for resetting if it is not applied)
    const [appliedFilters, setAppliedFilters] = useState({ ...filterListStorageInitial });

    const [isAscendingOrder, setIsAscendingOrder] = useState(false);

    const [orderByColumn, setOrderByColumn] = useState<ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN>(ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES.turnover);

    function handleRequestSort(
        event: React.MouseEvent<HTMLElement>,
        clickedOrderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN
    ) {
        let newAscendingOrder = isAscendingOrder;
        let newOrderByColumn = clickedOrderByColumn;

        if (clickedOrderByColumn === orderByColumn) {
            newAscendingOrder = !isAscendingOrder;
            setIsAscendingOrder(newAscendingOrder);
        } else {
            setOrderByColumn(newOrderByColumn);
        }

        setRows(
            sortNestedArrayByOrder(
                ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES[orderByColumn],
                ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_ENTITY_NAME,
                isAscendingOrder,
                rows,
                'childrenFinancialData'
            )
        );
    }

    const [areRowsLoaded, setRowsLoaded] = useState(false);
    const [areFiltersListsLoaded, setFiltersListsLoaded] = useState(false);
    const isLoaded = areRowsLoaded && areFiltersListsLoaded;

    const isReadyToShowRef = useRef(false);

    isLoaded && (isReadyToShowRef.current = true);

    const [rows, setRows] = useState<Array<IReportFinancialPerformanceRowModel>>([]);
    const [generalFinancialData, setGeneralFinancialData] = useState({});

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);

    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    const loadDataHandler = async ({
        newFilters = appliedFilters,
    }: {
    newFilters?: IFinancialPerformanceReportFilterList;
  } = {}) => {
        setRowsLoaded(false);

        const payload = generateFinancialPerformanceSearchPayload(
            newFilters,
            timeZone
        );

        apiRequests.getFinancialPerformancePage =
        Api.FinancialPerformanceReport.GetFinancialPerformancePage(payload);

        try {
            const { generalFinancialData, betshopsFinancialData } =
        await apiRequests.getFinancialPerformancePage;

            generalFinancialData && setGeneralFinancialData(generalFinancialData);

            const sortedData = sortNestedArrayByOrder(
                ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES.turnover,
                ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_ENTITY_NAME,
                isAscendingOrder,
                betshopsFinancialData,
                'childrenFinancialData'
            );

            setRows(sortedData);

            setRowsLoaded(true);
        } catch (error) {
            throwNetworkError(error);
        }
    };

    useEffect(() => {
        getAllBetshops(
            apiRequests,
            setFiltersLists,
            globalSettings?.user?.ClientId
        )
            .then(() => getEntities(apiRequests, setFiltersLists))
            .then(() => {
                setFiltersListsLoaded(true);
                loadDataHandler();
            })
            .catch(throwNetworkError);

        return () => cancelRequests(apiRequests);
    }, []);

    useEffect(() => {
        const filtersListsInitial: IFinancialPerformanceReportBaseFilterList = {
            betShops: [],
            cashiers: [],
            terminals: [],
        };

        setFiltersLists({ ...filtersListsInitial });
    }, [translations]);

    const handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        setPage(newPage);
    };

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleFiltersApply = (filters) => {
        cancelRequests(apiRequests);

        if (isSaveFilters) {
            const filtersToStorage = {};

            for (let key in filterListInitial) {
                if (filterListInitial.hasOwnProperty(key)) {
                    filtersToStorage[key] = filters[key];
                }
            }

            localStorage.setItem(localStorageFiltersKeys.financialPerformanceReportFilters, JSON.stringify(filtersToStorage));
        }

        if (
            filters.toDate.getTime() - filters.fromDate.getTime() <=
            financialPerformanceReportMaxRangeInMilliseconds
        ) {
            setAlertMessage({ ...alertMessage, show: false });
            loadDataHandler({ newFilters: { ...filters } });
        } else {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-vauchers-reports-max-days-range'],
                    {
                        days: globalSettings?.settings?.FinancialPerformanceReportSettings
                            ?.MaxRangeDays,
                    }
                )}`,
            });
        }

        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.financialPerformance]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null,
            },
        });

        setPage(0);
        setAppliedFilters(filters);
    };

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.financialPerformanceReportFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    const resetToDefault = () => {
        handleFiltersApply({ ...filterListInitialWithDate });
    };

    const handleClearFilters = () => {
        localStorage.removeItem(localStorageFiltersKeys.financialPerformanceReportFilters);
        setAppliedFilters({ ...filterListInitialWithDate });
        setIsSaveFilters(false);
        loadDataHandler({ newFilters: { ...filterListInitialWithDate } });
    };

    const handleHideAlert = () => {
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.financialPerformance]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null,
            },
        });

        setAlertMessage({ ...alertMessage, show: false });
    };

    const handleLoadingExcel = async () => {
        if (rows.length == 0){
            setAlertMessage({ show: true, message: translations['emp-transaction-nodata-error'] });
        } else if (appliedFilters.toDate.getTime() - appliedFilters.fromDate.getTime() > financialPerformanceExcelReportMaxRangeInMilliseconds) {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-transaction-export-to-excel-error-days'],
                    { 'days': financialPerformanceReportMaxRangeINDays }
                )}`
            });
        } else {
            const payload = generateFinancialPerformanceSearchPayload(
                appliedFilters,
                timeZone
            );

            if (!pendingGetExport) {
                pendingGetExport = true;
                Api.FinancialPerformanceReport.GetExport(
                    setLanguageCookie(
                        {
                            ...payload,
                            reportType: EXPORT_REPORT_TYPE.FinancialPerformance,
                            orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN[orderByColumn],
                            isAscendingOrder
                        },
                        globalSettings.languages, 'languageId'))
                    .then(res => {
                        const {
                            jwtToken,
                            reportId
                        } = res;

                        if (jwtToken) {
                            !mqttClient?.connected && initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);

                            subscribeToMqtt({
                                topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                onMessageHandler: (response) => onMessageExcelHandler(
                                    response,
                                    reportId,
                                    globalSettings.settings.MqttSettigs.ReportsExportStatusTopic,
                                    REPORTS_NAMES.financialPerformance)
                            });
                        }
                    }).catch((error) => {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.financialPerformance]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                        throwNetworkError(error);
                    }).finally(() => pendingGetExport = false);
            }
        }
    };

    const handleRefreshData = () => {
        setPage(0);
        loadDataHandler();
    };

    const FinancialPerformanceReportFiltersWithParams = (
        <FinancialPerformanceReportFilters
            filtersLists={filtersLists}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleFiltersApply}
            filterListInitialWithDate={filterListInitialWithDate}
            handleClearFilters={handleClearFilters}
            handleSaveFilters={handleSaveFilters}
            isSaveFilters={isSaveFilters}
        />
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <Typography className={classes.title} component="h1" noWrap>
                        {translations['emp-reports-financial-performance-title']}
                    </Typography>
                    {isMobileView && (
                        <div className={classes.actions}>
                            <ButtonRefresh handleClick={handleRefreshData} />
                            {FinancialPerformanceReportFiltersWithParams}
                            {reportFinancialPerformanceExport &&
                                <div style={{ marginLeft: '8px' }}>
                                    <ButtonWithLinearProgress
                                        label={translations['emp-transaction-export-to-excel'] as string}
                                        value={valueExportToExcel}
                                        handleLoading={handleLoadingExcel}
                                        loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    />
                                </div>
                            }
                        </div>
                    )}
                </div>
                {isMobileView && (
                    <DateRangeLegend
                        translationKey="emp-reports-results-range-title"
                        fromDate={appliedFilters.fromDate}
                        toDate={appliedFilters.toDate}
                    />
                )}
            </div>
            {<Divider className={classes.divider} />}
            <div className={classes.paper}>
                <BackdropSpinner open={!isLoaded} />
                {isReadyToShowRef.current && (
                    <>
                        {!isMobileView && (
                            <div className={classes.resultsHeader}>
                                <DateRangeLegend
                                    translationKey="emp-reports-results-range-title"
                                    fromDate={appliedFilters.fromDate}
                                    toDate={appliedFilters.toDate}
                                />
                                <div className={classes.options}>
                                    <ButtonRefresh handleClick={handleRefreshData} />
                                    {FinancialPerformanceReportFiltersWithParams}
                                    {reportFinancialPerformanceExport &&
                                        <div style={{ marginLeft: '8px' }}>
                                            <ButtonWithLinearProgress
                                                label={translations['emp-transaction-export-to-excel'] as string}
                                                value={valueExportToExcel}
                                                handleLoading={handleLoadingExcel}
                                                loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className={classes.alertWrapper}>
                            <AlertMessageFullWidth
                                label={alertMessage.message}
                                show={alertMessage.show}
                                handleHideAlert={handleHideAlert}
                            >
                                {translations['gen-dismiss']}
                            </AlertMessageFullWidth>
                        </div>
                        <Box className={classes.resultsWrapper}>
                            {globalSettings?.settings?.FinancialPerformanceReportSettings?.IsAggregationHeaderDisplayed && (
                                <div className={classes.summaryWrap}>
                                    {!isMobileView ? (
                                        <Grid container data-a="financial-performance-report-summary-grid">
                                            {Object.keys(generalFinancialData).map((key, index) => (
                                                <Grid key={index} item xs sm>
                                                    <Typography
                                                        className={classes.summaryText}
                                                        variant="body1"
                                                    >
                                                        {
                                                            translations[
                                                                generalFinancialDataTranslations[key]
                                                            ]
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={classes.summaryHeading}
                                                        variant="h5"
                                                    >
                                                        {translations[
                                                            generalFinancialDataTranslations[key]
                                                        ] === 'Total Bets'
                                                            ? generalFinancialData[key]
                                                            : formatNumberToCurrencyString(
                                                                generalFinancialData[key],
                                                                numberStyles(
                                                                    globalSettings?.settings?.Currency
                                                                ),
                                                                globalSettings?.settings?.CurrencySign,
                                                                globalSettings?.settings?.CurrencyFormat
                                                            )}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <Grid container alignItems="center">
                                            <Grid
                                                key="turnover"
                                                item
                                                xs={4}
                                                sm
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Typography
                                                    className={classes.summaryText}
                                                    style={{ textAlign: 'left' }}
                                                    variant="body1"
                                                >
                                                    {
                                                        translations[
                                                            generalFinancialDataTranslations['turnover']
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={classes.summaryHeading}
                                                    style={{ textAlign: 'left', fontSize: '1rem' }}
                                                    variant="h5"
                                                >
                                                    {formatNumberToCurrencyString(
                                                        generalFinancialData['turnover'],
                                                        numberStyles(globalSettings?.settings?.Currency),
                                                        globalSettings?.settings?.CurrencySign,
                                                        globalSettings?.settings?.CurrencyFormat
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                key="winnings"
                                                item
                                                xs={4}
                                                sm
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Typography
                                                    className={classes.summaryText}
                                                    style={{ textAlign: 'center' }}
                                                    variant="body1"
                                                >
                                                    {
                                                        translations[
                                                            generalFinancialDataTranslations['winnings']
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={classes.summaryHeading}
                                                    style={{ textAlign: 'center', fontSize: '1rem' }}
                                                    variant="h5"
                                                >
                                                    {formatNumberToCurrencyString(
                                                        generalFinancialData['winnings'],
                                                        numberStyles(globalSettings?.settings?.Currency),
                                                        globalSettings?.settings?.CurrencySign,
                                                        globalSettings?.settings?.CurrencyFormat
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                key="grossWin"
                                                item
                                                xs={4}
                                                sm
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Typography
                                                    className={classes.summaryText}
                                                    style={{ textAlign: 'right' }}
                                                    variant="body1"
                                                >
                                                    {
                                                        translations[
                                                            generalFinancialDataTranslations['grossWin']
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={classes.summaryHeading}
                                                    style={{ textAlign: 'right', fontSize: '1rem' }}
                                                    variant="h5"
                                                >
                                                    {formatNumberToCurrencyString(
                                                        generalFinancialData['grossWin'],
                                                        numberStyles(globalSettings?.settings?.Currency),
                                                        globalSettings?.settings?.CurrencySign,
                                                        globalSettings?.settings?.CurrencyFormat
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid key="winningsPaid" item xs={4} sm>
                                                <Typography
                                                    className={classes.summaryText}
                                                    style={{ textAlign: 'left' }}
                                                    variant="body1"
                                                >
                                                    {
                                                        translations[
                                                            generalFinancialDataTranslations['winningsPaid']
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={classes.summaryHeading}
                                                    style={{ textAlign: 'left', fontSize: '1rem' }}
                                                    variant="h5"
                                                >
                                                    {formatNumberToCurrencyString(
                                                        generalFinancialData['winningsPaid'],
                                                        numberStyles(globalSettings?.settings?.Currency),
                                                        globalSettings?.settings?.CurrencySign,
                                                        globalSettings?.settings?.CurrencyFormat
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid key="numberOfTotalBets" item xs={4} sm>
                                                <Typography
                                                    className={classes.summaryText}
                                                    style={{ textAlign: 'center' }}
                                                    variant="body1"
                                                >
                                                    {
                                                        translations[
                                                            generalFinancialDataTranslations[
                                                                'numberOfTotalBets'
                                                            ]
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={classes.summaryHeading}
                                                    style={{ textAlign: 'center', fontSize: '1rem' }}
                                                    variant="h5"
                                                >
                                                    {generalFinancialData['numberOfTotalBets']}
                                                </Typography>
                                            </Grid>
                                            <Grid key="handlePerBet" item xs={4} sm>
                                                <Typography
                                                    className={classes.summaryText}
                                                    style={{ textAlign: 'right' }}
                                                    variant="body1"
                                                >
                                                    {
                                                        translations[
                                                            generalFinancialDataTranslations['handlePerBet']
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={classes.summaryHeading}
                                                    style={{ textAlign: 'right', fontSize: '1rem' }}
                                                    variant="h5"
                                                >
                                                    {formatNumberToCurrencyString(
                                                        generalFinancialData['handlePerBet'],
                                                        numberStyles(globalSettings?.settings?.Currency),
                                                        globalSettings?.settings?.CurrencySign,
                                                        globalSettings?.settings?.CurrencyFormat
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </div>
                            )}
                            {!isMobileView ? (
                                <div>
                                    {rows.length ? (
                                        <FinancialPerformanceReportTable
                                            page={page}
                                            rows={sortNestedArrayByOrder(
                                                ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES[
                                                    orderByColumn
                                                ],
                                                ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_ENTITY_NAME,
                                                isAscendingOrder,
                                                rows,
                                                'childrenFinancialData'
                                            )}
                                            rowsPerPage={rowsPerPage}
                                            handleChangePage={handleChangePage}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            handleSort={handleRequestSort}
                                            order={
                                                isAscendingOrder ? Order.ascending : Order.descending
                                            }
                                            orderByColumn={orderByColumn}
                                        />
                                    ) : (
                                        <Box paddingTop={12}>
                                            <Grid container className={classes.justifyCenter} >
                                                <Grid item>
                                                    <NoResultsFound onResetClick={resetToDefault} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {rows.length ? (
                                        <FinancialPerformanceReportList
                                            page={page}
                                            rows={rows}
                                            rowsPerPage={rowsPerPage}
                                            handleChangePage={handleChangePage}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            order={
                                                isAscendingOrder ? Order.ascending : Order.descending
                                            }
                                        />
                                    ) : (
                                        <Box paddingTop={12}>
                                            <Grid container className={classes.justifyCenter} >
                                                <Grid item>
                                                    <NoResultsFound onResetClick={resetToDefault} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </div>
                            )}
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default FinancialPerformanceReport;
