import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useContext,
} from 'react';

import {
    Drawer,
    Divider,
} from '@mui/material';

import {
    EPOS_FILTERS_TITLES,
    IFilterListItem,
} from 'const';

import {
    IVoidsReprintsReportBaseFilterList,
    IVoidsReprintsReportFilterList,
    checkOpenExtension,
    countFilterQty,
    voidsReprintsReportSearchListInitial as searchListInitial,
    toggleFilters,
} from 'pages/Reports/business';

import { FilterButton, Header, ControlPanel } from 'components/Filters/Filters';
import { FiltersBox } from 'components/Filters/FiltersBox/FiltersBox';
import FiltersWithSearch from 'components/Filters/FiltersWithSearch/FiltersWithSearch';
import DateTimePicker from 'pages/Users/AuditLogs/components/DateTimePicker/DateTimePicker';
import ThresholdFilter from 'components/ThresholdFilter/ThresholdFilter';

import useStyles from 'pages/Reports/Tickets/TicketsReportFilters/styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const initOpenExpansions = {};

const filtersBoxes = [
    { label: 'entitiesEmpIds', title: 'emp-reports-voids-reprints-executed-by-title' },
    { label: 'supervisorsIds', title: 'emp-reports-voids-reprints-authorized-by-title' },
];
const initialValidityState = {
    timeRange: true,
};

const BETSHOPS = 'betShops';

const checkValidity = (validityState) => Object.values(validityState).every(isValid => isValid);

const VoidsReprintsReportFilters = function ({
    filtersLists,
    appliedFilters,
    setAppliedFilters,
    filterListInitialWithDate,
    handleClearFilters,
    handleSaveFilters,
    isSaveFilters,
}: {
    filtersLists: IVoidsReprintsReportBaseFilterList;
    appliedFilters: IVoidsReprintsReportFilterList;
    setAppliedFilters: (filters: IVoidsReprintsReportFilterList) => void;
    filterListInitialWithDate: IVoidsReprintsReportFilterList;
    handleClearFilters: () => void;
    handleSaveFilters: () => void;
    isSaveFilters: boolean;
}) {
    const classes = useStyles({});

    const { globalSettings }: GlobalContextModel = useContext(GlobalContext);

    const [filters, setFilters] = useState(filterListInitialWithDate);
    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInitial);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState(initOpenExpansions);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setFilters({ ...appliedFilters });
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
    }, Object.values(appliedFilters));

    const [validityState, setValidityState] = useState({ ...initialValidityState });
    const isApplyDisabled = !checkValidity(validityState);
    const setTimeRangeInvalid = useCallback((isInvalid) => {
        setValidityState(validityState => ({ ...validityState, timeRange: !isInvalid }));
    }, []);

    const handleOpenDrawer = () => {
        setFilters({ ...appliedFilters });
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
    };

    const handleFilterClick = useCallback((key: string, item: IFilterListItem) => {
        setFilters(filters => {
            const filterArr = toggleFilters(item, filters[key]);

            return {
                ...filters,
                [key]: filterArr,
            };
        });
    }, []);

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        setAppliedFilters({ ...filters });
        setSearchInFiltersList({ ...searchListInitial });
    };

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...initOpenExpansions });
        setFilters({ ...filterListInitialWithDate });
        setSearchInFiltersList({ ...searchListInitial });
        handleClearFilters();
    };

    const handleExpanded = useCallback((key: string) => {
        setOpenExpansions(openExpansions => (
            {
                ...openExpansions,
                [key]: !openExpansions[key],
            }
        ));
    }, []);

    const changeDates = useCallback((dates) => {
        setFilters(filters => ({ ...filters, ...dates }));
    }, []);

    const thresholdFilterValidityHandler: (key: string, isValid: boolean) => void =
        useCallback((key, isValid) => {
            setValidityState(validityState => ({ ...validityState, [key]: isValid }));
        }, []);

    const thresholdFilterChangeHandler: (key: string, value: number | Array<number>) => void =
        useCallback((key, value) => {
            setFilters(filters => ({ ...filters, [key]: value }));
        }, []);

    return (
        <>
            <FilterButton
                filterQty={countFilterQty(appliedFilters)}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        <DateTimePicker
                            setApplyDisabled={setTimeRangeInvalid}
                            fromDate={filters.fromDate}
                            toDate={filters.toDate}
                            changeDates={changeDates}
                        />
                        <Divider />
                        <FiltersWithSearch
                            label={BETSHOPS}
                            title={EPOS_FILTERS_TITLES.location}
                            data={filtersLists[BETSHOPS]}
                            activeFilterIds={filters[BETSHOPS]}
                            appliedActiveFilters={appliedFilters[BETSHOPS]}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions[BETSHOPS]}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[BETSHOPS].text}
                            isSearchFieldShown={searchInFiltersList[BETSHOPS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                        <FiltersBox
                            label="approvalTypes"
                            title="emp-reports-voids-reprints-action"
                            data={filtersLists['approvalTypes']}
                            activeFilterIds={{ ['approvalTypes']: filters['approvalTypes'] }}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions['approvalTypes']}
                            handleExpanded={handleExpanded}
                            translationKey=""
                        />
                        {filtersBoxes.map(({ label, title }) => (
                            <FiltersBox
                                key={label}
                                label={label}
                                title={title}
                                data={filtersLists[label]}
                                activeFilterIds={{ [label]: filters[label] }}
                                handleFilterClick={handleFilterClick}
                                expanded={openExpansions[label]}
                                handleExpanded={handleExpanded}
                                translationKey={''}
                            />)
                        )}
                        <ThresholdFilter
                            label="minDocumentCost"
                            title="emp-reports-voids-reprints-cost-title"
                            data={filters['minDocumentCost']}
                            inputComparisonLabel=">"
                            inputPrefix={globalSettings.settings.CurrencySign}
                            formatValue
                            onChange={thresholdFilterChangeHandler}
                            validityHandler={thresholdFilterValidityHandler}
                            expanded={openExpansions['minDocumentCost']}
                            handleExpanded={handleExpanded}
                            translationKey={''}
                        />
                    </div>
                    <ControlPanel
                        disabled={isApplyDisabled}
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                        onSaveFilters={handleSaveFilters}
                        isSaveFilters={isSaveFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default VoidsReprintsReportFilters;
